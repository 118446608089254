import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { SignIn } from './app/modules/auth';
import { Home } from './app/pages';
import PrivateRoute from './app/ui-components/privateRoute/PrivateRoute';

const routes = () => (
  <React.Fragment>
    <Router>
      <Switch>
      <Route path='/sign-in' render= {()=> <SignIn target={window.location.href}/>} />
        <PrivateRoute path='/' component={Home} />
      </Switch>
    </Router>
  </React.Fragment>
);

export default routes;
