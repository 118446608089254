import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../styles/show-credits.css';

const ShowCredits = () => {
  const history = useHistory();
  const displayableCredits = useSelector((state) => state.interview.displayableCredits);

  const startInterview = () => {
    history.push(`/question/${displayableCredits.complianceName}/${ displayableCredits.interviewId}/${displayableCredits.interviewSessionId}`);
  };

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>Credits Available</h2>
            <table className='table'>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Code</th>
                  <th>Category</th>
                  <th>Question</th>
                </tr>
              </thead>
              <tbody>
                {displayableCredits.questions
                  ? displayableCredits.questions.map((question, index) => (
                      <tr
                        className='cursor-default'
                        key={index}
                      >
                        <td>{index + 1}</td>
                        <td>{question.reprivataCode}</td>
                        <td>{question.category}</td>
                        <td>{question.question}</td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          </div>
          <div className='col-md-1 align-right'>
                <button
                  type='button'
                  onClick={() => startInterview()}
                  className='btn btn-primary btn-sm'
                >
                  Start Interview
                </button>
              </div>          
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShowCredits;
