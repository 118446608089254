import axios from 'axios';
import { url } from '../constants';

const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class DocumentService {
  static getAllDocuments() {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.documents.allDocuments}`)
      .then((response) => response);
  }

  static updateDocumentName(doc) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.documents.updateDocumentName}`, doc)
      .then((response) => response);
  }
}

export default DocumentService;
