import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { interviewAction } from '../../../redux/modules/interview';
import './../styles/Interviews.css';
import Table from 'react-bootstrap/Table'


const Interviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [pageHeading, setPageHeading] = useState([]);
  const[isProgress,setIsProgress] = useState(true);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  let location = useLocation();

  useEffect(() => {
    // For syncing categories with admin
    stableDispatch(interviewAction.syncAllCategories()).then(() => {
      if (location.pathname.endsWith('all') || location.pathname.endsWith('Card')) {
        stableDispatch(interviewAction.getAllInterviews(1)).then((response) => {
          setPageHeading('All Assessments');
          setIsProgress(false);
          setInterviews(response.data);
        });
      } else if (location.pathname.endsWith('unfinished')) {
        stableDispatch(interviewAction.getUnfinishedInterviews(1)).then((response) => {
          // interviews = response.data;
          setPageHeading('Assessments In-Progress');
          setInterviews(response.data);
        });
      }
    });
  }, [stableDispatch]);

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>{pageHeading}</h2>
            <Table responsive>
              <thead>
                <tr>
                  <th class='align-center' >Framework Name</th>
                  <th class='align-center' >Issuer</th>
                  <th class='align-center' >Framework Type</th>
                  <th class='align-center'>Assessment Start</th>
                  {!isProgress ? <th class='align-center'>Implemented</th>:''}
                 {!isProgress ? <th class='align-center'>Assessment End</th> :''}
                </tr>
              </thead>
              <tbody class='align-center'>
                {interviews.map((item, index) => (
                  <tr
                    className='cursor-pointer'
                    key={index}
                    onDoubleClick={() => {
                      if (location.pathname.endsWith('Card')) {
                        console.log('scoreCards');
                      } else {
                        history.push(`/interviews/${item.id}`);
                      }
                    }}
                  >
                    <td>{item.protocol}</td>
                    <td>{item.protocolIssuer}</td>
                    <td>{item.protocolFramework}</td>
                    <td>
                      {item.interviewStartDateTime
                        ? moment(item.interviewStartDateTime).format('MM-DD-YYYY HH:mm')
                        : ''}
                    </td>
                    {!isProgress ? <td>{item.totalImplemented}/{item.totalQuestion}</td>:''}
                    {!isProgress ? 
                    <td>
                      {item.interviewEndDateTime
                        ? moment(item.interviewEndDateTime).format('MM-DD-YYYY HH:mm')
                        : ''}
                    </td> : ''}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Interviews;
