import { ElementCreditService } from '../../services';
import actionType from './action-type';

const updateElementCredit = (elementCredit) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });
  return ElementCreditService
    .updateElementCredit(elementCredit)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getElementCreditForOrganization = (organizationId) => (dispatch) => {
  return ElementCreditService
    .getElementCreditForOrganization(organizationId)
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

const setDisplayableCredits = (creditData) => (dispatch) => {
  dispatch({ type: actionType.SET_DISPLAYABLE_CREDITS, data: creditData });
};


export default {
  setDisplayableCredits,
  getElementCreditForOrganization,
  updateElementCredit,
};
