import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserCommentAndDocumentsDlg from '../components/UserCommentAndDocumentsDlg';

const ComplianceCertificateDetail = () => {
  const elements = useSelector((state) => state.scoreCard.certificateElementsCredit);
  const history = useHistory();
  const [currentElementCredit, setCurrentElementCredit] = useState(null);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [isPopupReadonly, setIsPopupReadonly] = useState(false);

  const computerIsCompleted = (elementCredit) => {
    var documentRequired = elementCredit.element.documentRequired;
    var explanationRequired = elementCredit.element.explanationRequired;
    if (documentRequired.isCompleted) {
      return true;
    } else if (documentRequired && explanationRequired) {
      return false;
    } else if ((documentRequired == null || documentRequired == false) && explanationRequired) {
      return false;
    } else if ((explanationRequired == null || explanationRequired == false) && documentRequired) {
      return false;
    } else if (
      (documentRequired == null || documentRequired == false) &&
      (explanationRequired == null || explanationRequired == false)
    ) {
      return true;
    }
  };

  const viewQuestion = () => {
    setShowQuestionDialog(true);
  };

  const onHideQuestionDialog = () => {
    setShowQuestionDialog(false);
  };

  const onDoubleClicked = (index) => {
    if (index > -1) {
      var elementCredit = elements[index];
      setCurrentElementCredit(elementCredit);
      setIsPopupReadonly(elementCredit.isCompleted);
      // if (!elementCredit.isCompleted)
      viewQuestion();
    }
  };

  const computeDocumentionRequired = (elementCredit) => {
    if (
      (elementCredit.element.documents === null || elementCredit.element.documents.length == 0) &&
      elementCredit.element.documentRequired
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <UserCommentAndDocumentsDlg
        selectedElementCredit={currentElementCredit ? currentElementCredit : ''}
        showDialog={showQuestionDialog}
        hideDialog={onHideQuestionDialog}
        isReadOnly={isPopupReadonly}
      />
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>Compliance Certificate Detail</h2>
            <h4>
              The following Elements will be added to your Transcript. Please complete the Elements that need
              further information:
            </h4>
            <table className='table'>
              <thead>
                <tr>
                  <th>No</th>
                  <th class='width-10'>Code</th>
                  <th className='width-20'>Category</th>
                  <th>Question</th>
                  <th class='width-06 align-center'>Documention Required</th>
                  <th class='width-06 align-center'>Explanation Required</th>
                  <th class='width-06 align-center'>Completed</th>
                </tr>
              </thead>
              <tbody>
                {elements
                  ? elements.map((elementCredit, index) => (
                      <tr key={index} onDoubleClick={() => onDoubleClicked(index)}>
                        <td>{index + 1}</td>
                        <td>{elementCredit.element.reprivataCode}</td>
                        <td>{elementCredit.element.categoryName}</td>
                        <td>{elementCredit.element.question}</td>
                        <td class='align-center'>
                          {computeDocumentionRequired(elementCredit) ? (
                            <i class='fa fa-exclamation-circle icon-red' title='Credited'></i>
                          ) : (
                            <i class='fas fa-check' title='Marked Yes'></i>
                          )}
                        </td>
                        <td class='align-center'>
                          {elementCredit.element.explanationRequired && !elementCredit.userExplanation ? (
                            <i class='fa fa-exclamation-circle icon-red' title='Credited'></i>
                          ) : (
                            <i class='fas fa-check' title='Marked Yes'></i>
                          )}
                        </td>
                        <td class='align-center'>
                          {elementCredit.isCompleted ? (
                            <i class='fas fa-thumbs-up' title='Completed'></i>
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComplianceCertificateDetail;
