import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { interviewAction } from '../../../redux/modules/interview';
import ScoreCard from '../../score-card/components/score-card';
import '../styles/audit-report.css';

const AuditReport = () => {
  const [interviews, setInterviews] = useState(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let { organizationId } = useParams();

  useEffect(() => {
    if (organizationId) {
      stableDispatch(interviewAction.getFullInterviewsForOrganization(organizationId)).then((response) => {
        let interviews = response.data;
        setInterviews(interviews);
      });
    }
  }, [organizationId]);

  function calculateTimeElapsed(startDateTime, endDateTime) {
    var minutes = moment(endDateTime).diff(moment(startDateTime), 'minutes');

    if (minutes > 60) {
      var hours = moment(endDateTime).diff(moment(startDateTime), 'hours');
      return hours + ' Hrs ' + (minutes - hours * 60) + ' Min';
    } else if (minutes <= 0) {
      var seconds = moment(endDateTime).diff(moment(startDateTime), 'seconds');
      return seconds + ' Seconds';
    } else {
      return minutes + ' Min';
    }
  }

  function renderSession(minterviewSessions) {

   // console.log("minterviewSessions--", minterviewSessions);
   let minterview = minterviewSessions && minterviewSessions.interview && minterviewSessions.interview.interviewSessions || [];
    let validSessions = minterview.filter((session) => {
      if (session.sessionEndDateTime) {
        return session;
      }

    });
    //console.log("validSessions--", validSessions);

    return (
      <div className='row card-body'>
        {validSessions
          ? validSessions.map((session, index) => (
            <div className='attempt'>
              <div>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Session-{index + 1} Stats</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Session Start:</strong>
                      </td>
                      <td>
                        {session.sessionStartDateTime
                          ? moment(session.sessionStartDateTime).format('MM-DD-YYYY HH:mm:ss')
                          : ''}
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Session End:</strong>
                      </td>
                      <td>
                        {session.sessionEndDateTime
                          ? moment(session.sessionEndDateTime).format('MM-DD-YYYY HH:mm:ss')
                          : 'In-Progress'}
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Time Elapsed:</strong>
                      </td>
                      <td>
                        {session.sessionEndDateTime
                          ? calculateTimeElapsed(
                            session.sessionStartDateTime,
                            session.sessionEndDateTime
                          )
                          : ''}
                      </td>
                    </tr>

                    <tr className='cursor-default'>
                      <td>
                        <strong>Total Questions:</strong>
                      </td>
                      <td>
                        <strong> {session.scoreCard ? session.scoreCard.total : 0}</strong>
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Viewed:</strong>
                      </td>
                      <td>
                        <strong> {session.scoreCard ? session.scoreCard.viewed : 0}</strong>
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Yes:</strong>
                      </td>
                      <td>
                        <strong> {session.scoreCard ? session.scoreCard.yes : 0}</strong>
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>No:</strong>
                      </td>
                      <td>
                        <strong> {session.scoreCard ? session.scoreCard.no : 0}</strong>
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Answered:</strong>
                      </td>
                      <td>
                        <strong> {session.scoreCard ? session.scoreCard.answered : 0}</strong>
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>
                        <strong>Completed:</strong>
                      </td>
                      <td>
                        <strong>
                          {' '}
                          {session.scoreCard ? session.scoreCard.completed : 0}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <ScoreCard scoreCard={session.scoreCard} displayTabularStats='false' />
              </div>
            </div>
          ))
          : ''}
      </div>
    );


  }

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='row'>
            <h2>Audit Report</h2>
            {interviews && interviews.map
              ? interviews.map((interview, index) => (
                  <div className='card dark-border'>
                    <div className='card-header header-dark'>
                      <div className='row'>
                        <div className='col-md-4'>
                          <i className='fa fa-clipboard-list' aria-hidden='true'></i> &nbsp;{' '}
                          <strong>Framework:</strong> {interview.protocol}
                        </div>
                        <div className='col-md-4'>
                          <strong>Assessment Start:</strong>{' '}
                          {interview.interviewStartDateTime
                            ? moment(interview.interviewStartDateTime).format('MM-DD-YYYY HH:mm')
                            : ''}
                        </div>
                        <div className='col-md-4 align-right'>
                          <strong>Assessment End: </strong>{' '}
                          {interview.interviewEndDateTime
                            ? moment(interview.interviewEndDateTime).format('MM-DD-YYYY HH:mm')
                            : 'In-Progress'}
                        </div>
                      </div>
                      {renderSession ({interview})}
                    </div>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuditReport;
