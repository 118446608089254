import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { interviewAction } from '../../../redux/modules/interview';
import { ElementCreditAction } from '../../../redux/modules/element-credit';
import { notification } from '../../../redux/services';
import moment from 'moment-timezone';

import '../styles/audit-report.css';

const UserCommentsAndDocument = ({ selectedElementCredit, hideDialog, isReadOnly }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, getValues, reset, trigger } = useForm();
  const [isFileAttached, setFileAttached] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [userExplanation, setUserExplanation] = useState('');
  const [documents, setDocuments] = useState([]);

  const userExplanationChange = (event) => {
    console.log(selectedElementCredit);
    const userExplanation = event.target.value;
    if (userExplanation) {
      selectedElementCredit.userExplanation = userExplanation;
      setUserExplanation(userExplanation);
    } else {
      selectedElementCredit.userExplanation = '';
    }
  };

  useEffect(() => {
    if (selectedElementCredit) setUserExplanation(selectedElementCredit.userExplanation);
    selectedElementCredit.element.documents
      ? setDocuments(selectedElementCredit.element.documents)
      : setDocuments([]);

    if (!selectedElementCredit.element.documentRequired) {
      setFileAttached(true);
    }
  }, [selectedElementCredit]);

  const downloadFile = (docName) => {
    dispatch(interviewAction.downloadOriginalDoc(docName));
  };

  const handleChange = (event) => {
    const { originalDoc } = getValues();
    if (originalDoc.length) {
      selectedElementCredit.originalDoc = originalDoc;
      setFileAttached(true);
      setFileName(originalDoc[0].name);
      // uploadFile(documentInfo);
    } else {
    }
  };

  const uploadFile = (elementcredit) => {
    // make sure same document is not previosly loaded
    const formData = getFormData(elementcredit);
    formData.append('id', selectedElementCredit.id);
    dispatch(ElementCreditAction.updateElementCredit(formData)).then((document) => {
      selectedElementCredit.userExplanation = document.data.userExplanation;
      notification.success(`Element credit updated`);
    });
  };

  const computeIsCompleted = () => {
    if (
      !selectedElementCredit.element.explanationRequired &&
      !selectedElementCredit.element.documentRequired
    ) {
      selectedElementCredit.isCompleted = true;
    } else if (
      selectedElementCredit.element.explanationRequired &&
      selectedElementCredit.userExplanation &&
      selectedElementCredit.element.documentRequired &&
      isFileAttached
    ) {
      selectedElementCredit.isCompleted = true;
    } else if (
      selectedElementCredit.element.explanationRequired &&
      selectedElementCredit.userExplanation &&
      !selectedElementCredit.element.documentRequired
    ) {
      selectedElementCredit.isCompleted = true;
    } else if (
      selectedElementCredit.element.documentRequired &&
      isFileAttached &&
      !selectedElementCredit.element.explanationRequired
    ) {
      selectedElementCredit.isCompleted = true;
    }
  };

  const getFormData = (docinfo) => {
    const formData = new FormData();
    for (const key of Object.keys(docinfo)) {
      const keyValue = docinfo[key];
      if (key === 'originalDoc') {
        if (keyValue) formData.append('originalDoc', keyValue[0]);
      } else if (keyValue != null) {
        formData.append(key, keyValue);
      }
    }
    return formData;
  };

  const saveDataAndCloseform = () => {
    if (!isReadOnly) {
      computeIsCompleted();
      uploadFile(selectedElementCredit);
    }
    hideDialog();
  };

  return (
    <React.Fragment>
      <div className='body'>
        <div className='row clearfix'>
          <div className='col-md-12'>
            <form onSubmit={handleSubmit(selectedElementCredit)}>
              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-3'>Question:</label>
                  <div className='col-md-9'>
                    <textarea
                      className='form-control text-left fontBold'
                      name='qestion'
                      rows='3'
                      readonly
                      value={selectedElementCredit.element ? selectedElementCredit.element.question : ''}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label
                    className={classNames({
                      'col-md-3 ': !selectedElementCredit.element.explanationRequired,
                      'col-md-3 colorred': selectedElementCredit.element.explanationRequired,
                    })}
                  >
                    {selectedElementCredit.element.explanationRequired
                      ? 'Explanation Required (*)'
                      : 'Please Explain'}
                  </label>
                  <div className='col-md-9'>
                    <textarea
                      className='form-control'
                      name='userExplanation'
                      rows='3'
                      value={userExplanation}
                      onChange={userExplanationChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label
                    className={classNames({
                      'col-md-3 ': !selectedElementCredit.element.documentRequired,
                      'col-md-3 colorred': selectedElementCredit.element.documentRequired,
                    })}
                  >
                    {selectedElementCredit.element.documentRequired
                      ? 'Document Required (*)'
                      : 'Supporting Document'}
                  </label>

                  <div className='col-md-9 row'>
                    {fileName && (
                      <div className='col-md-7 document-filename'>
                        <input className='form-control' readOnly type='text' value={fileName} />
                      </div>
                    )}

                    {!isReadOnly ? (
                      <div className='col-md-3 upload-file-btn d-flex align-items-center'>
                        <input
                          id='originalDocFileUploadId'
                          type='file'
                          name='originalDoc'
                          ref={register(selectedElementCredit)}
                          onChange={handleChange}
                          style={{ display: 'none' }}
                        />

                        <button
                          type='button'
                          onClick={() => document.getElementById('originalDocFileUploadId').click()}
                          className='btn btn-primary btn-sm'
                        >
                          Select File
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 padding-top-10'>
                  <button
                    type='button'
                    className='btn btn-primary float-right mr-1'
                    onClick={() => saveDataAndCloseform()}
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className='row'>
                {documents && documents.length > 0 ? (
                  <div className='col-md-12'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Supporting Document</th>
                          <th>Create Date</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents
                          ? documents.map((item, index) => (
                              <tr key={index}>
                                <td className='width-01'>{index + 1}</td>
                                <td>{item.srcDocumentName.replace(/^.*[\\\/]/, '')}</td>
                                <td>
                                  {item.createdDate ? moment(item.createdDate).format('MM-DD-YYYY') : ''}
                                </td>
                                <td>
                                  <a
                                    href='#/'
                                    onClick={() =>
                                      downloadFile(item.srcDocumentName.replace(/^.*[\\\/]/, ''))
                                    }
                                    className='text-underline mr-3'
                                  >
                                    View
                                  </a>
                                </td>
                              </tr>
                            ))
                          : ''}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserCommentsAndDocument;
