import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import DocumentNameChange from '../components/documentNameChange';

const DocumentNameChangeDlg = ({ selectedDocument, showDialog, hideDialog }) => {
  useEffect(() => {
    console.log(showDialog);
  }, [selectedDocument]);

  const handleClose = () => {
    hideDialog(false);
  };

  return (
    <>
      <Modal show={showDialog} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Document Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocumentNameChange selectedDocument={selectedDocument} hideDialog={() => hideDialog()} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentNameChangeDlg;
