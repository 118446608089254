import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authService } from '../../redux/services';
let path = new URL(window.location.href).pathname;
let name = '?target=' + path;

const PrivateRoute = ({ component: Component, ...rest }) => (
  //windows.location.href
  <Route
    {...rest}
    render={(props) =>
      authService.isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: '/sign-in', search:name}} />
    }
  />
);

export default PrivateRoute;
