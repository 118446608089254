import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import HomeRoutes from '../home-routes';
import { authService } from './../../../redux/services';
import { TopNavigation } from '../../../ui-components/navigation';
import SessionTimer from './../../Modals/Components/timeout-modal'
import { NavLink } from 'react-router-dom'
import '../styles/home.css';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const HomeComponent = () => {



  const userNamesById = { '/compliances': ' / Begin New Assessment' ,
                            '/protocol-information' : ' / Framework ',
                            '/interviews/unfinished' : ' / Assessments In-Progress',
                            '/interviews' : ' / Assessments',
                            '/dashboard' : ' / Dashboard',
                            '/upload-compliance-certificate' : ' / Apply Completed Assessment Credits',
                            '/interviews/scorecards/:organizationId?' : ' / Score Card',
                            '/documents' : ' / Documents',
                            '/transcript/:organizationId?' :' / Transcript',
                            '/interviews/:interviewId?' :' / All Assessments',
                            '/audit-report/:organizationId?' : ' / Audit Report',
                            '/reverse-mapping' : ' / Reverse Mapping',

                          '/': 'Home'}

  const customMatch = (match) =>{
    console.log("match =", match);
    console.log("match.match.path =", match.match.path);
    console.log('match.location.pathname', match.location.pathname);
  //  if(match.match.path != match.location.pathname)
   // return null;
    if(userNamesById[match.match.path]){
      return userNamesById[match.match.path] ;;
    } else {
      return null;
    }

  };
  const DynamicUserBreadcrumb = ({ match }) => (
    <span>{ customMatch(match)}</span>
  );

  
  const routes = [
    { path: '/dashboard', breadcrumb: customMatch },
    { path: '/settings', breadcrumb: customMatch  },
    { path: '/profile', breadcrumb: customMatch },

    { path: '/protocol-information/:id', breadcrumb: null },

    { path: '/compliances', breadcrumb: customMatch },

    { path: '/compliance-setup', breadcrumb: customMatch },
    { path: '/complianceCertificateDetail', breadcrumb: customMatch },
    { path: '/question/:complianceName?/:interviewId?/:interviewSessionId?/:questionIndex?', breadcrumb: customMatch },
    { path: '/interviews/unfinished', breadcrumb: customMatch },

    { path: '/interviews/ReviewQuestions/:complianceName?/:interviewId?/:interviewSessionId?', breadcrumb: customMatch },
    {path:'/interviews',breadcrumb: null},
    { path: '/interviews/scorecards', breadcrumb: null },
    { path: '/interviews/scorecards/:organizationId?', breadcrumb: customMatch },
    { path: '/interviews/scorecards/:organizationId?/session/:sessionId?', breadcrumb: null},

    { path: '/interviews/:interviewId?', breadcrumb: customMatch },
    { path: '/audit-report', breadcrumb: null },

    { path: '/audit-report/:organizationId?', breadcrumb: customMatch },
    { path: '/transcript', breadcrumb: null },

    { path: '/transcript/:organizationId?', breadcrumb: customMatch },
    { path: '/upload-compliance-certificate', breadcrumb: customMatch },
    { path: '/interview/credits', breadcrumb: customMatch },
    { path: '/documents', breadcrumb: customMatch },
    { path: '/dashboard', breadcrumb: customMatch },
    { path: '/reverse-mapping', breadcrumb: customMatch },



    { path: '/protocol-information', breadcrumb: customMatch },
    { path: '/compliances', breadcrumb: customMatch },
    {path:'/interviews/:id',breadcrumb: customMatch},
    {path:'/',breadcrumb: customMatch}



  ];
  const breadcrumbs = useBreadcrumbs(routes);
  /*
  breadcrumbs.map((item, index) => {
    (index == breadcrumbs.length - 1) ? item.active = true : item.active = false;
    return item
  });
  */
  return (
    <React.Fragment>
      <TopNavigation />
      <div id='wrapper'>
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <div className='container-fluid'>
              <div className='row clearfix'>
                <div className='col-lg-12'>
                  <>
                  {breadcrumbs.map(({
                                     match,
                                     breadcrumb
                                    }) => (
                  <span key={match.url}>
                  <NavLink to={match.url}>{breadcrumb}</NavLink>
        </span>
      ))}
                  </>
                  <div className='card'>
                    <HomeRoutes />
                    <SessionTimer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a className='scroll-to-top rounded' href='#page-top'>
          <i className='fas fa-angle-up'></i>
        </a>
      </div>
      <footer><span>Copyright &copy; 2021 Reprivata USA Inc, All rights reserved </span></footer>
    </React.Fragment>
  );
}

export default HomeComponent;
