import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './root-reducer';

const composeEnhancers = composeWithDevTools({
  trace: true,
});

const configureStore = (initialState) => {
  const middleware = applyMiddleware(thunk, logger);

  const store = createStore(rootReducer(), initialState, composeEnhancers(middleware));

  return store;
};

export default configureStore;
