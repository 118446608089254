const IS_LOADING = 'IS_LOADING';
const SET_DISPLAYABLE_CREDITS = 'SET_DISPLAYABLE_CREDITS';
const COMPLIANCE_CREDIT_INFORMATION = 'COMPLIANCE_CREDIT_INFORMATION';
const CERTIFCATE_ELEMENTS_CREDIT = 'CERTIFCATE_ELEMENTS_CREDIT';

export default {
  IS_LOADING,
  SET_DISPLAYABLE_CREDITS,
  COMPLIANCE_CREDIT_INFORMATION,
  CERTIFCATE_ELEMENTS_CREDIT,
};
