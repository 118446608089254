import actionType from './action-type';

const initialState = {
  isLoading: false,
  interviewSessionDetail: {},
  fullInterviewDetail: {},
  interviewSession: {},
  interviewCompliances: {},
  displayableCredits: {},
  complianceCreditInfomration: {},
  certificateElementsCredit: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case actionType.LOAD_SCORECARD:
      return {
        ...state,
        scoreCard: action.data,
      };
    case actionType.LOAD_INTERVIEW_SESSION_DETAIL:
      return {
        ...state,
        interviewSessionDetail: action.data,
      };

    case actionType.FULL_INTERVIEW_DETAIL:
      return {
        ...state,
        fullInterviewDetail: action.data,
        interviewSessionDetail: action.data.interviewSessions[0].interviewSessionDetail,
      };
    case actionType.LOAD_INTERVIEW_SESSION:
      return {
        ...state,
        interviewSession: action.data,
      };
    case actionType.SET_DISPLAYABLE_CREDITS:
      return {
        ...state,
        displayableCredits: action.data,
      };

    case actionType.LOAD_INTERVIEW_COMPLIANCES:
      return {
        ...state,
        interviewCompliances: action.data,
      };

    case actionType.COMPLIANCE_CREDIT_INFORMATION:
      return {
        ...state,
        complianceCreditInfomration: action.data,
      };

    case actionType.CERTIFCATE_ELEMENTS_CREDIT:
      return {
        ...state,
        certificateElementsCredit: action.data,
      };

    default:
      return state;
  }
};
