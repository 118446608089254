const url = {
  compliance: {
    setupCompliance: '/compliances/SetupCompliances',
    getCompliances: '/compliances',
    getActiveLockedCompliances: '/compliances/ActiveAndLocked',
    getIssuedCompliances: '/compliances/PublishedCompliance',
    getIssuedCompliancesReports: '/compareprotocol',
    getActiveLockedWithDetails: '/compliances/AllComplinacesFromAdmin',
    getActiveLockedInProgressCompliances: '/compliances/ActiveLockedInProgress',
    getFullCompliance: '/compliances/{complianceId}/detail',
    downloadOriginalDoc: '/compliances/downloadOriginalDoc',
  },
  category: {
    syncCategories: '/categories?page=1&pageSize=-1',
  },
  interview: {
    setupInterview: '/interviews/setup-interview',
    interviewSessionDetail: '/InterviewSessionDetail',
    questionStatus: '/implementationmaster/list',
    downloadOriginalDoc: '/InterviewSessionDetail/downloadOriginalDoc',
    updateInterview: '/interviews',
    GetInterviewCompliances: '/interviews',
    unfinishedInterviews: '/interviews/organization/{organizationId}/unfinished',
    allInterviews: '/interviews/organization/{organizationId}/all',
    fullInterviews: '/interviews/organization/{organizationId}/full',
    allInterviewsWithSessions: '/interviews/organization/{organizationId}/all/sessions',
    fullInterviewDetail: '/interviews',
    certificate: '/certificate/{organizationId}',
    scoreCard: '/scorecard',
    createInterviewSession: '/interviews',
    addCreditScore: '/interviews/creditscore',
    getCreditElementForOrg: '/interviews/elementcredit',
    transcriptSetupInterview: '/interviews/transcript/setup-interview',
    updateElementCredit: '/interviews/elementcredit',
  },
  settings: {
    logoUpload: '/companylogo',

  },
  profile: {
    postProfileInfo: '/organizationsave/updateprofile',
    getProfileInfo: '/organizationsave/getdetails',

  },
  documents: {
    allDocuments: '/documents',
    updateDocumentName: '/documents/updateDocumentName',
  },
};

export default url;
