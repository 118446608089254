import axios from 'axios';
import { notification, authService } from '../redux/services';
import { sharedConstant } from '../redux/constants';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = false;
axios.defaults.crossDomain = true;
axios.interceptors.request.use(
  function (config) {
    config.headers.authorization = `${sharedConstant.tokenType} ${authService.getToken()}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 404) {
      authService.logoutFromApp();
      window.location.reload();
      return Promise.reject(error.response);
    }

    const errorResponse = error.response;
    let errorMessage = 'An uncaught error occurred';

    if (errorResponse) {
      if (errorResponse.data && errorResponse.data.message) {
        errorMessage = errorResponse.data.message;
      } else if (errorResponse.statusText) {
        errorMessage = `${errorMessage} - ${errorResponse.statusText}`;
      }
    } else {
      errorMessage = 'An error occurred. please contact your administrator';
    }

    notification.error(errorMessage);

    return Promise.reject(error.response);
  }
);
