import { authService } from '../../services';
import actionType from './action-type';

const signIn = (userInfo) => (dispatch) => {
  dispatch({ type: actionType.IS_SIGNING_IN, data: true });

  return authService
    .signIn(userInfo)
    .then((response) => {
      authService.loginIntoApp(response.data);
      const authenticatedUserInfo = authService.getLoggedInUserInfo();
      dispatch({ type: actionType.AUTHENTICATED_USER, data: authenticatedUserInfo });
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({ type: actionType.IS_SIGNING_IN, data: false });
    });
};

export default { signIn };
