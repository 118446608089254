import { TranscriptService } from '../../services';

const getCertificateForOrganization = (organizationId) => (dispatch) => {
  return TranscriptService.getCertificateForOrganization(organizationId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('Error occured in transcript-action.getTranscriptForOrganization method ::: ', error);
    });
};

const TransscriptInterviewSetup = (elements) => (dispatch) => {
  return TranscriptService.transcriptInterviewSetup(elements)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('Error occured in transcript-action.TransscriptInterviewSetup method ::: ', error);
    });
};

export default {
  getCertificateForOrganization,
  TransscriptInterviewSetup,
};
