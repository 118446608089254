import { interviewService } from '../../services';
import actionType from './action-type';

const setupInterview = (complianceData) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .setupInterview(complianceData)
    .then((response) => {
      // dispatch({ type: actionType.LOAD_COMPLIANCE, data: response.data });
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({ type: actionType.IS_LOADING, data: false });
    });
};

const createInterviewSession = (interviewId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .createInterviewSession(interviewId)
    .then((response) => {
      dispatch({ type: actionType.LOAD_INTERVIEW_SESSION, data: response.data });
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({ type: actionType.IS_LOADING, data: false });
    });
};

const getInterviewSessionDetail = (interviewSessionId) => (dispatch) => {
  return interviewService
    .getInterviewSessionDetail(interviewSessionId)
    .then((response) => {
      dispatch({ type: actionType.LOAD_INTERVIEW_SESSION_DETAIL, data: response.data });
      return response;
    })
    .catch((error) => {});
};

const getInterviewQuestionStatus = (type) => (dispatch) => {
  return interviewService
    .getInterviewQuestionStatus(type)
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

const getInterviewCompliances = () => (dispatch) => {
  return interviewService
    .getInterviewCompliances()
    .then((response) => {
      dispatch({ type: actionType.LOAD_INTERVIEW_COMPLIANCES, data: response.data });
      return response;
    })
    .catch((error) => {});
};

const getFullInterviewDetail = (id) => (dispatch) => {
  return interviewService
    .getFullInterviewDetail(id)
    .then((response) => {
      dispatch({ type: actionType.FULL_INTERVIEW_DETAIL, data: response.data });
      return response;
    })
    .catch((error) => {});
};

const downloadOriginalDoc = (fileName, id) => (dispatch) => {
  return interviewService
    .downloadOriginalDoc(fileName, id)
    .then((response) => {
      const name = fileName;
      const responseData = response.data;
      const downloadedFile = new Blob([responseData], { type: responseData.type });
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = `${name}`;
      a.href = URL.createObjectURL(downloadedFile);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);

      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateInterviewSessionDetail = (sessionId, questions, interviewId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .updateInterviewSessionDetail(sessionId, questions, interviewId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getUnfinishedInterviews = (organizationId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .getUnfinishedInterviews(organizationId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getAllInterviews = (organizationId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .getAllInterviews(organizationId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const syncAllCategories = () => (dispatch) => {
  return interviewService
    .syncCategoriesWithAdmin()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const getFullInterviewsForOrganization = (organizationId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .getFullInterviewsForOrganization(organizationId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getAllInterviewsWithSessions = (organizationId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .getAllInterviewsWithSessions(organizationId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const uploadQuestionDoc = (question) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return interviewService
    .UploadQuestionDocument(question)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  setupInterview,
  getInterviewSessionDetail,
  getInterviewQuestionStatus,
  downloadOriginalDoc,
  updateInterviewSessionDetail,
  getUnfinishedInterviews,
  getAllInterviews,
  syncAllCategories,
  getFullInterviewDetail,
  getAllInterviewsWithSessions,
  getFullInterviewsForOrganization,
  uploadQuestionDoc,
  createInterviewSession,
  getInterviewCompliances,
};
