import axios from 'axios';
import { url } from '../constants';

const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class ScoreCardService {
  static AddCreditScore(complianceInfo) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.interview.addCreditScore}`, complianceInfo)
      .then((response) => response);
  }

  static getScoreCard(sessionId) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.scoreCard}/${sessionId}`)
      .then((response) => response);
  }
}

export default ScoreCardService;
