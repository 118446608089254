import axios from 'axios';
import { url } from '../constants';

const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class TranscriptService {
  static getCertificateForOrganization(organizationId) {
    const fullUrl = `${complianceAssessmentApiUrl}${url.interview.certificate.replace(
      '{organizationId}',
      organizationId
    )}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static transcriptInterviewSetup(elements) {
    var uncompleteQuestions = elements.filter((x) => !x.isCompleted);

    return axios
      .post(`${complianceAssessmentApiUrl}${url.interview.transcriptSetupInterview}`, uncompleteQuestions)
      .then((response) => response);
  }
}

export default TranscriptService;
