import React, { Component, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import SettingService from './../../../redux/services/settings-service';
import { notification } from '../../../redux/services';
import './../styles/settings.css';


const complianceAdminApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;


export default class UploadLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            currentFile: null,
            previewImage: complianceAdminApiUrl + "/companylogo/getCompanylogo",
            progress: 0,
            message: "",
            imageInfos: [],
        };
    }
    componentDidMount() {
    }
    selectFile(e) {
        this.setState({
            currentFile: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0])
        }, () => {
            console.log("this.state.currentFile.type ->", this.state.currentFile.type)
            if (!['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(this.state.currentFile.type))
                notification.error(`The selected file format is not allowed. Only JPG, PNG and SVG is allowed.`);
            else
                this.setState({ disabled: false })
        })
    }
    upload() {
        this.setState({
            progress: 0,
        });
        SettingService.logoUpload(this.state.currentFile, (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                this.setState({
                    message: "Logo updated",
                });
            })
            .catch((err) => {
                this.setState({
                    progress: 0,
                    message: "Could not upload the image!",
                    currentFile: undefined,
                });
            });
    }
    render() {
        const {
            disabled,
            currentFile,
            previewImage,
            progress,
            message,
            imageInfos,
        } = this.state;

        return (
            <React.Fragment>
                <div className='body'>
                    <div className='col-sm-8'>
                        <div className='card-header shadow-card-header'> Company Logo</div>
                        <div className='card-body'>
                            <div className="row">
                                <div className='col-sm-5'>
                                    <div className='form-group row'>
                                        {/* <label className='col-md-4'>Select your logo</label> */}
                                        <div className='col-md-8'>
                                            <input type="file" accept="image/*"
                                                onChange={this.selectFile.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {previewImage && (
                                    <div className="col-sm-3">
                                        <img className="img-logo" accept="image/jpg, image/jpeg, image/png" src={previewImage} alt="" />
                                    </div>
                                )}
                            </div>
                            {currentFile && (
                                <div className="progress my-3">
                                    <div
                                        className="progress-bar progress-bar-info progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: progress + "%" }}
                                    >
                                        {progress}%
                                            </div>
                                </div>
                            )}

                            {message && (
                                <div className="alert alert-secondary mt-3" role="alert">
                                    {message}
                                </div>
                            )}

                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <div className='col-md-1 offset-11'>
                                        <button
                                            className="btn btn-success btn-sm"
                                            disabled={!currentFile || disabled}
                                            onClick={this.upload.bind(this)}
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}   
