import { ProfileService } from '../../services';
import actionType from './action-type';

const postProfileData = (payload) => (dispatch) => {
  return ProfileService.postProfileData(payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

const getProfileData = (id) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });
  return ProfileService.getProfileData(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getProfileData,
  postProfileData,
};
