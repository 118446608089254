import axios from 'axios';
import { url } from '../constants';

const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class settingService {
  static logoUpload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append("FileName", 'CompanyLogo');
    formData.append("originalImage", file);
    return axios.post(`${complianceAssessmentApiUrl}${url.settings.logoUpload}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }).then((response) => {
      return response
    });

  }

}

export default settingService;
