import PropTypes from 'prop-types'
import React from 'react'
import IdleTimer from 'react-idle-timer'
import { Modal, Button } from 'react-bootstrap';
import './../styles.css';
import { authService } from '../../../redux/services';
import constants from './../../../redux/constants/shared-constants';
import { useHistory, useLocation } from 'react-router-dom';

const loginRedirectPath = '/' // A full page load


class SessionTimer extends React.Component {
    constructor() {
        super();
        this.state = { isOpen: false };
        // IdleTimer
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
    }
    static propTypes = {
        confirmTime: PropTypes.number,
        expireTime: PropTypes.number,
        extendURL: PropTypes.string,
        onConfirmOpen: PropTypes.func,
        isGuest: PropTypes.bool,
        env: PropTypes.string,
    }

    static defaultProps = {
        onConfirmOpen: () => { },
        confirmTime: 1 * 60 * 1000, // 14 minutes
        expireTime: 1000 * 60 * 3, // 15 minutes // 30 sec
        extendURL: '/myaccount/extendSession',
    }

    componentDidMount() {
       // if (this.props.env !== 'pools') {
        //this.startTimers()
        //}
    }

    componentWillUnmount() {
        this.clearTimers()
    }

    open = () => {
        this.setState({ isOpen: true })
        this.props.onConfirmOpen()
    }

    close = () => {
        this.setState({ isOpen: false })
    }

    forceLogout = async () => {
       // await authService.saveAuthData(window.location.pathname);
       console.log('u are forced logout');
       console.log("window.location.href=", window.location.href);
       let path = new URL(window.location.href).pathname;
       let name = window.location.origin + '/sign-in?target=' + path;

        await authService.logoutFromApp();
        this.close();
        console.log("name=", name);
        window.location = name;
       // window.location = loginRedirectPath
    }       
    forceLogout1 = async () => {
        // await authService.saveAuthData(window.location.pathname);
        //let history = useHistory();
        console.log('u are forced logout1');
        console.log("window.location.href=", window.location.href);


         authService.logoutFromApp();
        // history.push('/sign-in');
        // this.close();
         window.location = "/sign-in"
     }

    onSessionExtended = () => {
        this.close()
        this.clearTimers()
        this.idleTimer.reset();
        //this.startTimers()
    }

    clearTimers = () => {
        //clearTimeout(this.expirationTimer)
        this.idleTimer.reset();
    }

    startTimers = () => {
        console.log(" caliing startTimers");
        console.log("window.location=", window.location);
        console.log("window.location.href=", window.location.href);
        this.expirationTimer = setTimeout(() => {
            this.forceLogout();
        }, this.props.expireTime)
    }

    extendSession = async () => {
        console.log(" caliing extendSession");

        const token = localStorage.getItem(constants.tokenContainer);
        authService.refreshToken(token)
        this.onSessionExtended()
    }

    handleOnActive = event => {
        console.log('user is active', event)
        console.log('time remaining', this.idleTimer.getRemainingTime());
      }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 27}
                    onIdle={this.handleOnIdle}
                    onActive={this.handleOnActive}
                    debounce={500}
                />
                <Modal
                    scrollable={true}
                    show={this.state.isOpen}
                    onHide={this.extendSession}
                    backdrop="static"
                    keyboard={false} size='lg'
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Your session is about to end.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            You've been inactive for a while.
                            For your security, we'll automatically sign you out in approximately 3 minutes.
                            You may choose <b>Stay signed in</b> to continue or <b>Sign out</b> if you're done.
                        </p>
                        {/* <p className="class-countdown">{this.state.time.m} : {this.state.time.s}</p> */}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.forceLogout1}>Sign out</Button>
                        <Button onClick={this.extendSession} variant="primary">Stay signed in</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        )
    }
    handleOnIdle(event) {
        console.log('user is idle', event)
        this.open()
        console.log("window.location.href=", window.location.href);
        this.startTimers(true);
    }
}

export default SessionTimer