import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import 'suneditor/dist/css/suneditor.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/protocol-details.css';

import { complianceAction } from '../../../redux/modules/compliance';
import { interviewAction } from '../../../redux/modules/interview';
import { ElementCreditAction } from '../../../redux/modules/element-credit';

const ProtocolDetails = () => {
  const [complianceData, setComplianceData] = useState(null);
  let { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [complianceName, setComplianceName] = useState('');
  const [elementQuestions, setElementQuestions] = useState('');

  useEffect(() => {
    if (id) {
      stableDispatch(complianceAction.getFullCompliance(id))
        .then((compliance) => {
          setComplianceData(compliance.data);
          setElementQuestions(compliance.data.totallElementquestions);
        })
        .catch(() => { });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setupInterview = () => {
    if (complianceData.totallElementquestions > 0) {
      console.log('complianceData ::::: ', complianceData);
      dispatch(interviewAction.setupInterview(complianceData))
        .then((response) => {
          console.log('setupInterview response ::::: ', response);
          if (response.data.hasCredits) {
            var displayableCredits = {
              ...response.data.displayableCredits,
              complianceName: '',
              interviewId: response.data.interviewId,
              interviewSessionId: response.data.interviewSessionId,
            };

            dispatch(ElementCreditAction.setDisplayableCredits(displayableCredits));

            history.push(`/interview/credits`);
          } else {
            history.push(
              `/question/${complianceData.name}/${response.data.interviewId}/${response.data.interviewSessionId}`
            );
          }
        })
        .catch((ex) => {
          console.log('Exception in dispatch(interviewAction.setupInterview(compliance.data)) :::: ', ex);
        });
    }
  };

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='container col-md-12'>
            {/* Compliance card begins here  */}
            <div className='card dark-border'>
              <div className='card-header header-dark'>
                <div className='row'>
                  <div className='col-md-4'>
                    <i className='fa fa-clipboard-list' aria-hidden='true'></i> &nbsp;{' '}
                    <strong>{complianceData ? complianceData.name : ''}</strong>
                  </div>
                  <div className='col-md-4'>Issued By: {complianceData ? complianceData.issuer : ''}</div>
                  <div className='col-md-4 align-right'>
                    Issued Date:{' '}
                    {complianceData ? moment(complianceData.issuerDate).format('MM-DD-YYYY') : ''}
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12 padding-top-10'>
                    <strong>Framework Type:</strong> {complianceData ? complianceData.frameworkName : ''}
                  </div>
                  <div className='col-md-12 padding-top-10'>
                    <strong>Link: </strong> {complianceData ? complianceData.link : ''}
                  </div>
                  <div className='col-md-12 padding-top-10'>
                    <strong>Original Doc: </strong>{' '}
                    {complianceData && complianceData.originalDocumentSource
                      ? complianceData.originalDocumentSource.split('_')[1]
                      : ''}
                  </div>
                  <div className='col-md-12 padding-top-20'>
                    <strong>Description:</strong> {complianceData ? complianceData.description : ''}
                  </div>
                  <div className='col-md-12 padding-top-20'>
                    <strong>Notes:</strong> {complianceData ? complianceData.notes : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-11'>
                <div className='form-group form-check'>
                  <strong> Control elements :</strong>{' '}
                  {complianceData ? complianceData.totallElementquestions : ''}
                </div>
              </div>
              <div className='col-md-1 align-right'>
                {
                  complianceData && complianceData.totallElementquestions ? (
                    <button type='button' onClick={() => setupInterview()} className='btn btn-primary btn-sm'>
                      Start
                    </button>
                  ) : (
                      <></>
                    )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProtocolDetails;
