import React, { useEffect, useState, useCallback, useRef } from 'react';
import { interviewAction } from '../../../redux/modules/interview';
import { ScoreCardAction } from '../../../redux/modules/score-card';
import { notification } from '../../../redux/services';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import 'react-datepicker/dist/react-datepicker.css';

import { complianceAction } from '../../../redux/modules/compliance';
import DocumentNameChangeDlg from '../../interview/components/documentNameChangeDlg';
import moment from 'moment';

const UploadComplianceCertificate = () => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [compliancesName, setCompliancesName] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState(null);
  const [selectedIssuerDate, setIssuerDate] = useState(null);
  const [selectedExpirationDate, setExpirationDate] = useState();
  const [selectedOriginalDoc, setSelectedOriginalDoc] = useState('');
  const [documents, setDocuments] = useState([]);
  const [clickedFile, setClickedFile] = useState(null);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const history = useHistory();

  useEffect(() => {
    register({ name: 'issuerDate' }, { required: true });
    register({ name: 'expirationDate' }, { required: true });
  }, [register]);

  useEffect(() => {
    stableDispatch(complianceAction.getAllFullCompliances()).then((response) => {
      stableDispatch(complianceAction.getCompliancesNames())
        .then((data) => {
          setCompliancesName(data.data);
          const certificateInfo = {
            complianceId: 0,
            originalDoc: null,
            issuer: '',
            notes: '',
          };

          const documentInfo = {
            originalDoc: null,
          };
          setDocumentInfo(documentInfo);

          setCertificateInfo(certificateInfo);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }, [stableDispatch]);

  const SaveSertificate = (certificateInfo) => {
    if (certificateInfo) {
      const interview = compliancesName.filter((x) => x.id == certificateInfo.complianceId);
      const intervInfo = interview[0];
      const { organizationId = 0 } = JSON.parse(localStorage.getItem('loggedInUserInfoContainer'));
      if (intervInfo) {
        const formData = getFormData(certificateInfo);
        formData.append('compliance', intervInfo.name);
        formData.append('OrganizationId', organizationId);
        dispatch(ScoreCardAction.AddCreditScore(formData)).then(() => {
          notification.success(`Compliance credit score added`);
          history.push(`/complianceCertificateDetail`);
        });
      }
    }
  };

  const handleIssuerDate = (selectedDate) => {
    setValue('issuerDate', selectedDate);
    setIssuerDate(selectedDate);
  };

  const handleExpirationDate = (selectedDate) => {
    setValue('expirationDate', selectedDate);
    setExpirationDate(selectedDate);
  };

  const handleOriginalDocFile = (event) => {
    const { originalDoc } = getValues();
    if (originalDoc.length) {
      setSelectedOriginalDoc(originalDoc[0].name);
      documentInfo.originalDoc = originalDoc;
      documentInfo.userDefinedName = originalDoc[0].name;
      documentInfo.srcDocumentName = originalDoc[0].name;
      uploadFile(documentInfo);
    } else {
      setSelectedOriginalDoc('');
    }
  };

  const getFileData = (docinfo) => {
    const formData = new FormData();
    for (const key of Object.keys(docinfo)) {
      const keyValue = docinfo[key];
      if (key === 'originalDoc') {
        if (keyValue.length) formData.append('originalDoc', keyValue[0]);
      } else if (key === 'issuerDate') {
        const issuerDateString = moment(keyValue).format('DD/MM/yyyy HH:mm:ss');
        formData.append('issuerDateString', issuerDateString);
      } else if (key === 'expirationDate') {
        const expirationDateString = moment(keyValue).format('DD/MM/yyyy HH:mm:ss');
        formData.append('expirationDateString', expirationDateString);
      } else {
        formData.append(key, keyValue);
      }
    }
    return formData;
  };

  const uploadFile = (documentInfo) => {
    // make sure same document is not previosly loaded
    if (documents.filter((x) => x.documentName == documentInfo.srcDocumentName).length == 0) {
      const formData = getFileData(documentInfo);
      formData.append('id', 0);
      formData.append('certificateId', 9999);
      formData.append('isComplianceDocument', true);
      formData.append('srcDocumentName', documentInfo.srcDocumentName);
      dispatch(interviewAction.uploadQuestionDoc(formData)).then((document) => {
        var docs = [];
        docs = [document.data, ...documents];

        setDocuments(docs);
        notification.success(`File uploaded`);
      });
    }
  };

  const getFormData = (docinfo) => {
    const formData = new FormData();
    for (const key of Object.keys(docinfo)) {
      const keyValue = docinfo[key];
      if (key === 'issuerDate') {
        const issuerDateString = moment(keyValue).format('DD/MM/yyyy HH:mm:ss');
        formData.append('issuerDateString', issuerDateString);
      } else if (key === 'expirationDate') {
        const expirationDateString = moment(keyValue).format('DD/MM/yyyy HH:mm:ss');
        formData.append('expirationDateString', expirationDateString);
      } else {
        formData.append(key, keyValue);
      }
    }
    return formData;
  };

  const downloadFile = (docName, id) => {
    dispatch(interviewAction.downloadOriginalDoc(docName, id));
  };

  const viewQuestion = () => {
    setShowQuestionDialog(true);
  };

  const onHideQuestionDialog = () => {
    setShowQuestionDialog(false);
  };

  const onDoubleClicked = (item, index) => {
    setClickedFile(item);
    viewQuestion();
    console.log(showQuestionDialog);
  };

  return (
    <React.Fragment>
      <DocumentNameChangeDlg
        selectedDocument={clickedFile}
        showDialog={showQuestionDialog}
        hideDialog={onHideQuestionDialog}
      />
      <div className='body'>
        <div className='col-sm-8'>
          <div className='card-header shadow-card-header'> Upload Compliance Certificate</div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(SaveSertificate)}>
              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-2'>Compliance Name</label>
                  <div className='col-md-10'>
                    <select
                      type='text'
                      className='form-control'
                      name='complianceId'
                      placeholder='Enter name'
                      ref={register({ required: 'Compliance is required' })}
                    >
                      <option value=''>Select Compliance </option>
                      {compliancesName.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage errors={errors} name='frameworkId' as={<span className='form-error' />} />
                  </div>
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-2'>Issued By</label>
                  <div className='col-md-10'>
                    {' '}
                    <input
                      type='text'
                      className='form-control'
                      name='issuer'
                      placeholder='Issued by'
                      ref={register({ required: 'Issuer required' })}
                    />
                    <ErrorMessage errors={errors} name='issuer' as={<span className='form-error' />} />
                  </div>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-2'>Issue date</label>
                  <div className='input-group form-group col-md-10'>
                    <DatePicker
                      id='issuerDatePicker'
                      className='form-control'
                      selected={selectedIssuerDate}
                      onChange={handleIssuerDate}
                      autoComplete='off'
                    />
                    <div
                      className='input-group-append'
                      onClick={() => document.getElementById('issuerDatePicker').click()}
                    >
                      <span className='input-group-text'>
                        <i className='fa fa-calendar'></i>
                      </span>
                    </div>
                    <div className='link-error'></div>
                    {}
                    {errors.issuerDate && <span className='form-error'>Issuer date is required</span>}
                  </div>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-2'>Expiration date</label>
                  <div className='input-group form-group col-md-10'>
                    <DatePicker
                      id='expirationDataPicker'
                      className='form-control'
                      selected={selectedExpirationDate}
                      onChange={handleExpirationDate}
                      autoComplete='off'
                    />
                    <div
                      className='input-group-append'
                      onClick={() => document.getElementById('expirationDataPicker').click()}
                    >
                      <span className='input-group-text'>
                        <i className='fa fa-calendar'></i>
                      </span>
                    </div>{' '}
                  </div>
                  <div className='link-error'></div>
                  {}
                  {errors.expirationDate && <span className='form-error'>Expiration date is required</span>}
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-2'>Notes</label>
                  <div className='col-md-10'>
                    <textarea
                      rows='4'
                      className='form-control no-resize'
                      name='notes'
                      ref={register()}
                      placeholder='Enter notes'
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='form-group row'>
                  <label className='col-md-2 d-flex align-items-center'>Original Doc</label>

                  <div className='col-md-10 row'>
                    {selectedOriginalDoc && (
                      <div className='col-md-8 document-filename'>
                        <input className='form-control' readOnly type='text' value={selectedOriginalDoc} />
                      </div>
                    )}

                    <div className='col-md-3 upload-file-btn d-flex align-items-center'>
                      <input
                        id='originalDocFileUploadId'
                        type='file'
                        name='originalDoc'
                        ref={register({ required: 'Please select a file' })}
                        onChange={handleOriginalDocFile}
                        style={{ display: 'none' }}
                      />

                      <button
                        type='button'
                        onClick={() => document.getElementById('originalDocFileUploadId').click()}
                        className='btn btn-primary btn-sm'
                      >
                        Upload File
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-12'>
                <div className='form-group row'>
                  <div className='col-md-1 offset-11'>
                    <button
                      type='submit'
                      onClick={() => SaveSertificate()}
                      className='btn btn-primary btn-sm next'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className='row'>
                {documents && documents.length > 0 ? (
                  <div className='col-md-12'>
                    <table className='table'>
                      <thead className='header'>
                        <tr>
                          <th></th>
                          <th>Supporting Document</th>
                          <th>Create Date</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents
                          ? documents.map((item, index) => (
                            <tr key={index} onDoubleClick={() => onDoubleClicked(item)}>
                              <td className='width-01'>{index + 1}</td>
                              <td>{item.userDefinedName}</td>
                              <td>
                                {item.createdDate ? moment(item.createdDate).format('MM-DD-YYYY') : ''}
                              </td>
                              <td>
                                <a
                                  href='#/'
                                  onClick={() => downloadFile(item.userDefinedName, item.id)}
                                  className='text-underline mr-3'
                                >
                                  View
                                  </a>
                              </td>
                            </tr>
                          ))
                          : ''}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    ''
                  )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadComplianceCertificate;
