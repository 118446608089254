import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { documentAction } from '../../../redux/modules/documents';
import { interviewAction } from '../../../redux/modules/interview';
import DocumentNameChangeDlg from '../../interview/components/documentNameChangeDlg';

import '../styles/document-list.css';

const DocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [clickedFile, setClickedFile] = useState(null);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);

  useEffect(() => {
    stableDispatch(documentAction.getAllDocuments()).then((response) => {
      console.log('Documents :::: ', response.data);
      setDocuments(response.data);
    });
  }, [stableDispatch]);

  const viewQuestion = () => {
    setShowQuestionDialog(true);
  };

  const onHideQuestionDialog = () => {
    setShowQuestionDialog(false);
  };

  const onDoubleClicked = (item) => {
    setClickedFile(item);
    viewQuestion();
    console.log(showQuestionDialog);
  };
  const downloadFile = (docName, id) => {
    dispatch(interviewAction.downloadOriginalDoc(docName, id));
  };

  return (
    <React.Fragment>
      <DocumentNameChangeDlg
        selectedDocument={clickedFile}
        showDialog={showQuestionDialog}
        hideDialog={onHideQuestionDialog}
      />
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>Documents</h2>
            <table className='table'>
              <thead>
                <tr>
                  <th className='width-01'>&nbsp;</th>
                  <th className='width-01'>Date Uploaded</th>
                  <th className='width-04'>Document Description</th>
                  <th className='width-06'>Original File Name</th>
                  <th className='width-01'>From Certificate</th>
                  <th className='width-01'>Certificate Name</th>
                  <th className='width-01'>Reprivata Code</th>
                  <th className='width-01'>View</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document, index) => (
                  <tr className='cursor-pointer' onDoubleClick={() => onDoubleClicked(document)}>
                    <td>{index + 1}</td>
                    <td>
                      {document.createdDate ? moment(document.createdDate).format('MM-DD-YYYY HH:MM') : ''}
                    </td>
                    <td>{document.userDefinedName}</td>
                    <td>{document.srcDocumentName}</td>
                    <td>{document.isBelongsToCertificate ? 'Y' : 'N'}</td>
                    <td>{document.certificateName}</td>
                    <td>{document.reprivataCode}</td>
                    <td>
                      <a
                        href='#/'
                        onClick={() => downloadFile(document.userDefinedName, document.id)}
                        className='text-underline mr-3'
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentList;
