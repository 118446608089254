import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ScoreCardAction } from '../../../redux/modules/score-card';
import constants from '../../../redux/constants/shared-constants';
import { ScoreCard } from '../../score-card'
import { Pie } from 'react-chartjs-2';
//import { chartColors } from "./colors";



import '../styles/score-card-viewer.css';

const ScoreCardViewer = () => {
  const [scoreCard, setScoreCard] = useState(null);
  const [completionStatsPieChartData, setCompletionStatsPieChartData] = useState(null);
  const [viewStatsPieChartData, setViewStatsPieChartData] = useState(null);
  //const [pieStats, setpieStats] = useState(null);

  /*
  const data = {
      maintainAspectRatio: false,
      responsive: true,
      labels: ["Completed", "Not Completed"],
      datasets: [
        {
          data: [scoreCard ? scoreCard.answered : 0, scoreCard ? scoreCard.total : 0],
          labels: ["Hum1","Hum2","Hum1","Hum2"],
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors
        }
      ]
    };
  const pieOptions = {
      legend: {
        display: true,
        position: "right"
    
      },
      elements: {
        arc: {
          borderWidth: 1
        }
      }
  };
  */
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const userInfo = JSON.parse(localStorage.getItem(constants.loggedInUserInfoContainer));
  let { sessionId } = useParams();


  const configureCompletionStatsPieChart = (scoreCard) => {
    var completionStatsPieChart = {
      labels: [],
      datasets: [
        {
          label: 'Completion Stats',
          backgroundColor: [],
          hoverBackgroundColor: [],
          data: [],
        },
      ],
    };

    if (scoreCard.completed > 0) {
      setPieChartValues(completionStatsPieChart, 'Completed', '#2FDE00', '#D2F5E3', scoreCard.completed);

      if (scoreCard.yes > 0) {
        var partialCompleted = 0;
        partialCompleted = scoreCard.yes - scoreCard.completed;
        setPieChartValues(
          completionStatsPieChart,
          'Partial Completed',
          '#00A6B4',
          '#94B4A4',
          partialCompleted
        );
      }

      var notCompleted = scoreCard.total - scoreCard.yes;
      setPieChartValues(completionStatsPieChart, 'Not Completed', '#FCDADA', '#FFA5A5', notCompleted);
    }

    setCompletionStatsPieChartData(completionStatsPieChart);
  };

  const configureViewStatsPieChart = (scoreCard) => {
    var viewStatsPieChart = {
      labels: [],
      datasets: [
        {
          label: 'View Stats',
          backgroundColor: [],
          hoverBackgroundColor: [],
          data: [],
        },
      ],
    };

    if (scoreCard.viewed > 0) {
      var notViewed = scoreCard.total - scoreCard.viewed;
      setPieChartValues(viewStatsPieChart, 'Viewed', '#C9DE00', '#C9DE00', scoreCard.viewed);
      setPieChartValues(viewStatsPieChart, 'Not Viewed', '#FCDADA', '#FFA5A5', notViewed);
    }

    setViewStatsPieChartData(viewStatsPieChart);
  };

  const setPieChartValues = (pieChart, label, backgroundColor, hoverBackgroundColor, dataPoint) => {
    pieChart.labels.push(label);
    pieChart.datasets[0].backgroundColor.push(backgroundColor);
    pieChart.datasets[0].hoverBackgroundColor.push(hoverBackgroundColor);
    pieChart.datasets[0].data.push(dataPoint);
  };
  let params = (new URL(document.location)).searchParams;
  let framework = params.get("framework");
  useEffect(() => {
    if (sessionId) {
      stableDispatch(ScoreCardAction.getScoreCard(sessionId))
        .then((response) => {
          let scoreCardData = response.data;
          configureCompletionStatsPieChart(scoreCardData);
          configureViewStatsPieChart(scoreCardData);
          console.log('Score Card ::: ', response);
          setScoreCard(scoreCardData);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [sessionId]);

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>{framework} Assessment for {userInfo ? userInfo.organizationName : ''}</h2>
          </div>
          <div className='row justify-content-center'>
            <div className='row sc-stats bg-success'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-star' title='Progress'></i>
              </div>
              <div className='fs-1 sc-stats-right '>{scoreCard ? scoreCard.answered : 0}/{scoreCard ? scoreCard.total : 0}</div>
              <div className='sc-stats-text'>Controls Addressed</div>
            </div>
            {/* <div className='row sc-stats'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-thumbs-up' title='Total Completed'></i>
              </div>
              <div className='sc-stats-right'>{scoreCard ? scoreCard.completed : 0}</div>
              <div className='sc-stats-text'>Total Completed</div>
            </div> */}
            <div className='row sc-stats bg-primary'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-check' title='Implemented'></i>
              </div>
              <div className='sc-stats-right'>{scoreCard ? scoreCard.implemented : 0}</div>
              <div className='sc-stats-text'>Implemented</div>
            </div>
            <div className='row sc-stats bg-warning'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-pencil-square-o' title='Partially Implemented'></i>
              </div>
              <div className='sc-stats-right'>{scoreCard ? scoreCard.partiallyImplemented : 0}</div>
              <div className='sc-stats-text'>Partially Implemented</div>
            </div>
            <div className='row sc-stats bg-info'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-calendar-check-o' title='Planned'></i>
              </div>
              <div className='sc-stats-right'>{scoreCard ? scoreCard.planned : 0}</div>
              <div className='sc-stats-text'>Planned</div>
            </div>
            <div className='row sc-stats bg-danger'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-times' title='Not Implemented'></i>
              </div>
              <div className='sc-stats-right'>{scoreCard ? scoreCard.notImplemented : 0}</div>
              <div className='sc-stats-text'>Not Implemented</div>
            </div>
            <div className='row sc-stats bg-dark'>
              <div className='sc-stats-left'>
                <i class='fas  fa-3x fa-exclamation-triangle' title='Not Applicable'></i>
              </div>
              <div className='sc-stats-right'>{scoreCard ? scoreCard.notApplicable : 0}</div>
              <div className='sc-stats-text'>Not Applicable</div>
            </div>
          </div>
          <div className='row'>
            <div className='score-card-table-view card dark-border'>
              <div className='card-header header-dark'>
                <div className='row'>
                  <div className='col-md-4'>
                    <strong>Statistics</strong>
                  </div>
                </div>
              </div>
              {scoreCard ? <ScoreCard scoreCard={scoreCard} displayTabularStats='true' /> : ''}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ScoreCardViewer;
