import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import '../styles/score-card.css';

const ScoreCard = ({ scoreCard, displayTabularStats }) => {
  const [completionStatsPieChartData, setCompletionStatsPieChartData] = useState(null);
  const [viewStatsPieChartData, setViewStatsPieChartData] = useState(null);
  const [viewImpStatsPieChartData, setImpStatsPieChartData] = useState(null);

  const configureCompletionStatsPieChart = (scoreCard) => {
    var completionStatsPieChart = {
      labels: [],
      datasets: [
        {
          label: 'Completion Stats',
          backgroundColor: [],
          hoverBackgroundColor: [],
          data: [],
        },
      ],
    };

    setPieChartValues(completionStatsPieChart, 'Completed', '#2FDE00', '#D2F5E3', scoreCard.completed ? scoreCard.completed : 0);

    if (scoreCard.yes > 0) {
      var partialCompleted = 0;
      partialCompleted = scoreCard.completed;
      setPieChartValues(
        completionStatsPieChart,
        'Partial Completed',
        '#00A6B4',
        '#94B4A4',
        partialCompleted
      );
    }

    var notCompleted = scoreCard.total - scoreCard.completed;
    setPieChartValues(completionStatsPieChart, 'Not Completed', '#FCDADA', '#FFA5A5', notCompleted);

    setCompletionStatsPieChartData(completionStatsPieChart);
  };

  const configureViewStatsPieChart = (scoreCard) => {
    var viewStatsPieChart = {
      labels: [],
      datasets: [
        {
          label: 'View Stats',
          backgroundColor: [],
          hoverBackgroundColor: [],
          data: [],
        },
      ],
    };

    if (scoreCard.viewed > 0) {
      var notViewed = scoreCard.total - scoreCard.viewed;
      setPieChartValues(viewStatsPieChart, 'Viewed', '#C9DE00', '#C9DE00', scoreCard.viewed);
      setPieChartValues(viewStatsPieChart, 'Not Viewed', '#FCDADA', '#FFA5A5', notViewed);
    }

    setViewStatsPieChartData(viewStatsPieChart);
  };

  const configureImpStatsPieChart = (scoreCard) => {
    const viewStatsPieChart = {
      labels: [],
      datasets: [
        {
          label: 'View Stats',
          backgroundColor: [],
          hoverBackgroundColor: [],
          data: [],
        },
      ],
    };
    setPieChartValues(viewStatsPieChart, 'Implemented', '#00B74A', '#00B74A', scoreCard.implemented);
    setPieChartValues(viewStatsPieChart, 'Partially Implemented', '#FFA900', '#FFA900', scoreCard.partiallyImplemented);
    setPieChartValues(viewStatsPieChart, 'Planned', '#39C0ED', '#39C0ED', scoreCard.planned);
    setPieChartValues(viewStatsPieChart, 'Not Implemented', '#F93154', '#F93154', scoreCard.notImplemented);
    setPieChartValues(viewStatsPieChart, 'Not Applicable', '#262626', '#262626', scoreCard.notApplicable);
    setImpStatsPieChartData(viewStatsPieChart);
  };

  const setPieChartValues = (pieChart, label, backgroundColor, hoverBackgroundColor, dataPoint) => {
    pieChart.labels.push(label);
    pieChart.datasets[0].backgroundColor.push(backgroundColor);
    pieChart.datasets[0].hoverBackgroundColor.push(hoverBackgroundColor);
    pieChart.datasets[0].data.push(dataPoint);
  };

  useEffect(() => {
    configureCompletionStatsPieChart(scoreCard);
    configureViewStatsPieChart(scoreCard);
    configureImpStatsPieChart(scoreCard);
  }, []);

  return (
    <React.Fragment>
      {displayTabularStats == 'true' ? (
      <div className='row card-body'>
        <div className={displayTabularStats == 'true' ? 'width-20' : 'hide'}>
          <table className='table'>
            <thead>
              <tr>
                <th>Stats</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr className='cursor-default'>
                <td>
                  <strong>Total Questions:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.total : 0}</strong>
                </td>
              </tr>
              <tr className='cursor-default'>
                <td>
                  <strong>Completed:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.completed : 0}</strong>
                </td>
              </tr>
              <tr className='cursor-default'>
                <td>
                  <strong>Implemented:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.implemented : 0}</strong>
                </td>
              </tr>
              <tr className='cursor-default'>
                <td>
                  <strong>Partially Implemented:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.partiallyImplemented : 0}</strong>
                </td>
              </tr>
              <tr className='cursor-default'>
                <td>
                  <strong>Planned:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.planned : 0}</strong>
                </td>
              </tr>
              <tr className='cursor-default'>
                <td>
                  <strong>Not Implemented:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.notImplemented : 0}</strong>
                </td>
              </tr>
              <tr className='cursor-default'>
                <td>
                  <strong>Not Applicable:</strong>
                </td>
                <td>
                  <strong> {scoreCard ? scoreCard.notApplicable : 0}</strong>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        {/* {(
          scoreCard && scoreCard.total ?
            <div className={displayTabularStats == 'true' ? 'score-card-chart-view width-30' : 'score-card-chart-view width-40'}>
              <Pie
                data={viewStatsPieChartData ? viewStatsPieChartData : {}}
                options={{
                  plugins: {
                    datalabels: {
                      color: 'black',
                      labels: {
                        title: {
                          font: {
                            weight: 1000,
                          },
                        },
                        value: {
                          color: 'green',
                        },
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: 'Viewed / Not Viewed',
                    fontSize: 20,
                    position: 'top',
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </div>
            : ''
        )} */}
        <div className='row card-body' >
        {
          scoreCard && scoreCard.total ? (
            <div className={displayTabularStats == 'true' ? 'score-card-chart-view ' : 'score-card-chart-view'}>
              <Pie
                data={completionStatsPieChartData}
                options={{
                  plugins: {
                    datalabels: {
                      color: 'black',
                      labels: {
                        title: {
                          font: {
                            weight: 1000,
                          },
                        },
                        value: {
                          color: 'green',
                        },
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: 'Completion Stats',
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              /> </div>)
            : ('')
        }
        {
          scoreCard && scoreCard.total ? (
            <div className={displayTabularStats == 'true' ? 'score-card-chart-view' : 'score-card-chart-view'}>
              <Pie
                data={viewImpStatsPieChartData}
                options={{
                  plugins: {
                    datalabels: {
                      color: 'black',
                      labels: {
                        title: {
                          font: {
                            weight: 1000,
                          },
                        },
                        value: {
                          color: 'green',
                        },
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: 'Implementation Stats',
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </div>)

            : ('')
        }
        </div>


      </div>) : ('') }
    </React.Fragment>
  );
};
export default ScoreCard;
