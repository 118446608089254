import { combineReducers } from 'redux';

import {
  complianceReducer,
  authReducer,
  interviewReducer,
  ScoreCardReducer,
  ElementCreditReducer,
  DocumentReducer
} from './modules';

const rootReducer = () =>
  combineReducers({
    auth: authReducer,
    compliance: complianceReducer,
    interview: interviewReducer,
    scoreCard: ScoreCardReducer,
    elementCredit: ElementCreditReducer,
    documents: DocumentReducer
  });

export default rootReducer;
