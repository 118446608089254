import actionType from './action-type';

const initialState = {
  isLoading: false,
  compliances: [],
  compliance: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case actionType.GET_COMPLIANCES:
      return {
        ...state,
        compliances: action.data,
      };
    case actionType.GET_COMPLIANCES_BY_ID:
      return {
        ...state,
        compliance: action.data,
      };
    default:
      return state;
  }
};
