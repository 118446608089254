import axios from 'axios';
import { url } from '../constants';

const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class ElementCreditService {
  static getElementCreditForOrganization(organizationId) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.getCreditElementForOrg}/${organizationId}`)
      .then((response) => response);
  }

  static updateElementCredit(elemenrCredit) {
    const fullurl = `${complianceAssessmentApiUrl}${url.interview.updateElementCredit}`;
    return axios.post(fullurl, elemenrCredit).then((response) => response);
  }
}

export default ElementCreditService;
