import actionType from './action-type';

const initialState = {
  isSigningIn: false,
  authenticatedUserInfo: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.IS_SIGNING_IN:
      return {
        ...state,
        isSigningIn: action.data,
      };
    case actionType.AUTHENTICATED_USER:
      return {
        ...state,
        authenticatedUserInfo: action.data,
      };
    default:
      return state;
  }
};
