import React from 'react';
import '../styles/questions-list.css';

const QuestionsList = ({ heading, interviewId, interviewEndDate, paragraphs, onDoubleClicked }) => {
  const checkDocumentation = (question) => {
    if (!question.documentRequired) {
      return '';
    } else if (question.documentRequired && question.documents && question.documents.length == 0) {
      return <i class='fas fa-exclamation-circle icon-red' title='Documentation Required'></i>;
    } else if (question.documentRequired && question.documents && question.documents.length > 0) {
      return <i class='fas fa-check' title='Documentation uploaded'></i>;
    }
  };

  const checkExplanation = (question) => {
    if (!question.explanationRequired) {
      return '';
    } else if (
      question.explanationRequired &&
      question.userExplanation &&
      question.userExplanation.length > 0
    ) {
      return <i class='fas fa-check' title='Explanation provided'></i>;
    } else {
      return <i class='fas fa-exclamation-circle icon-red' title='Explanation Required'></i>;
    }
  };

  const checkImplementationStatus = (question) => {
    console.log('question.implementationStatusID', question.implementationStatusID);
    if (!question.implementationStatusID) {
      return '';
    } else if ( question.implementationStatusID == 1) {
      return <i class='fas fa-check' title='Marked Yes'></i>;
    } else {
      return ''
    }
  };

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>{heading}</h2>
            <table className='table'>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Control Number</th>
                  <th>Control Category</th>
                  <th>Question</th>
                  <th class='width-06 align-center'>Completed</th>
                  <th class='width-04 align-center'>Implementation Status</th>
                  <th class='width-06 align-center'>Credited</th>
                </tr>
              </thead>
              <tbody>
                {paragraphs
                  ? paragraphs.map((paragraph, paragraphIndex) => (
                      <>
                        <tr key={paragraphIndex}>
                          <td colSpan='12'>
                            <strong>Paragraph Title:</strong>{' '}
                            {paragraph.paragraphTitle.trim().length > 0
                              ? paragraph.paragraphTitle
                              : 'Default'}
                            &nbsp;
                          </td>
                        </tr>
                        {paragraph.questions.map((question, index) => (
                          <tr
                            className={interviewEndDate ? 'cursor-default' : 'cursor-pointer'}
                            key={index + paragraphIndex}
                            onDoubleClick={() => onDoubleClicked(interviewId, index + paragraphIndex + 1)}
                          >
                            <td class='align-center'>
                              {index + paragraphIndex + 1} {question.isFlaged ? <i class='fas fa-flag' title='Flaged'></i> : ''}
                            </td>
                            <td>{question.paragraphControlNumber}</td>
                            <td>{question.category}</td>
                            <td>{question.question}</td>
                            <td class='align-center'>
                              {question.isCompleted ? <i class='fas fa-thumbs-up' title='Completed'></i> : ''}
                            </td>
                            <td class='align-center'>
                              {checkImplementationStatus(question)}
                            </td>
                            <td class='align-center'>
                              {question.gotCredit ? <i class='fas fa-check' title='Credited'></i> : ''}
                            </td>
                          </tr>
                        ))}
                      </>
                    ))
                  : ''}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuestionsList;
