import { complianceService } from '../../services';
import actionType from './action-type';

const setupCompliances = (compliances) => (dispatch) => {
  return complianceService
    .setupCompliances(compliances)
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

const getCompliances = () => (dispatch) => {
  return complianceService
    .getCompliances()
    .then((response) => {
      dispatch({ type: actionType.GET_COMPLIANCES, data: response.data });
      return response;
    })
    .catch((error) => {});
};

const getCompliancesReports = (payload) => (dispatch) => {
  return complianceService
    .getReverseMappingReport(payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

const getIssuedCompliances = () => (dispatch) => {
  return complianceService
    .getIssuedCompliances()
    .then((response) => {
      dispatch({ type: actionType.GET_COMPLIANCES, data: response.data });
      return response;
    })
    .catch((error) => {});
};

const getAllFullCompliances = () => (dispatch) => {
  return complianceService
    .getAllFullCompliances()
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

const getCompliancesNames = () => (dispatch) => {
  return complianceService
    .getCompliancesNames()
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

// const getCompliance = (id) => (dispatch) => {
//   return complianceService
//     .getCompliance(id)
//     .then((response) => {
//       dispatch({ type: actionType.GET_COMPLIANCES_BY_ID, data: response.data });
//       return response.data;
//     })
//     .catch((error) => {
//       return Promise.reject(error);
//     });
// };

const getFullCompliance = (complianceId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return complianceService
    .getFullCompliance(complianceId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({ type: actionType.IS_LOADING, data: false });
    });
};

const downloadOriginalDoc = (fileName) => (dispatch) => {
  return complianceService
    .downloadOriginalDoc(fileName)
    .then((response) => {
      const name = fileName.split('_')[1];
      const responseData = response.data;
      const downloadedFile = new Blob([responseData], { type: responseData.type });
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = `${name}`;
      a.href = URL.createObjectURL(downloadedFile);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);

      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getCompliances,
  getIssuedCompliances,
  getFullCompliance,
  downloadOriginalDoc,
  getCompliancesReports,
  getCompliancesNames,
  getAllFullCompliances,
  setupCompliances,
};
