import actionType from './action-type';

const initialState = {
  isLoading: false,
  scoreCard: {},
  certificateElementsCredit: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case actionType.LOAD_SCORECARD:
      return {
        ...state,
        scoreCard: action.data,
      };
    case actionType.CERTIFCATE_ELEMENTS_CREDIT:
      return {
        ...state,
        certificateElementsCredit: action.data,
      };
    default:
      return state;
  }
};
