import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ElementCreditAction } from '../../../redux/modules/element-credit';
import { interviewAction } from '../../../redux/modules/interview';
import { transcriptAction } from '../../../redux/modules/transcript';
import moment from 'moment-timezone';
import UserCommentAndDocumentsDlg from '../../certificate/components/UserCommentAndDocumentsDlg';
import certificateQuestionList from '../../certificate/components/certificateQuestionList';

const Transcript = () => {
  const [interviews, setInterviews] = useState(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let { organizationId } = useParams();
  const [elements, setElements] = useState(null);
  const history = useHistory();
  const [currentElementCredit, setCurrentElementCredit] = useState(null);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [isPopupReadonly, setIsPopupReadonly] = useState(false);
  const [documents, setDocuments] = useState([]);
  const userInfo = useSelector((state) => state.auth.authenticatedUserInfo);  

  useEffect(() => {
    if (organizationId) {
      stableDispatch(transcriptAction.getCertificateForOrganization(organizationId))
        .then((response) => {
          setInterviews(response.data);

          console.log('Transcript ::: ', response.data);
        })
        .catch((e) => {
          console.log(e);
        });

      getElementCredits();
    }
  }, [organizationId]);

  const getElementCredits = () => {
    stableDispatch(ElementCreditAction.getElementCreditForOrganization(organizationId))
      .then((response) => {
        setElements(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onDoubleClicked = (index) => {
    if (index > -1) {
      var elementCredit = elements[index];
      setCurrentElementCredit(elementCredit);
      //if (!elementCredit.isCompleted)

      setIsPopupReadonly(elementCredit.isCompleted);
      viewQuestion();
    }
  };

  const viewQuestion = () => {
    setShowQuestionDialog(true);
  };

  const onHideQuestiondialogDialog = () => {
    setShowQuestionDialog(false);
    getElementCredits();
  };

  const computeDocumentionRequired = (elementCredit) => {
    if (
      (elementCredit.element.documents === null || elementCredit.element.documents.length == 0) &&
      elementCredit.element.documentRequired
    ) {
      return true;
    } else {
      return false;
    }
  };

  const downloadFile = (docName) => {
    dispatch(interviewAction.downloadOriginalDoc(docName));
  };

  return (
    <React.Fragment>
      <UserCommentAndDocumentsDlg
        selectedElementCredit={currentElementCredit ? currentElementCredit : ''}
        showDialog={showQuestionDialog}
        hideDialog={onHideQuestiondialogDialog}
        isReadOnly={isPopupReadonly}
      />
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>Score Card For {userInfo ? userInfo.organizationName : ''}</h2>
            <h4>{interviews && interviews.length > 0 ? 'Compliance Certificate Detail' : ''}</h4>
          </div>
          <div className='row'>
            {interviews && interviews.length > 0 ? (
              interviews.map((interview, index) => (
                <div className='card dark-border'>
                  <div className='card-header header-dark'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <i className='fa fa-clipboard-list' aria-hidden='true'></i> &nbsp;{' '}
                        <strong>Protocol:</strong> {interview.compliance.name}
                      </div>
                      <div className='col-md-4'>
                        <strong>Protocol Issuer:</strong> {interview.compliance.issuer}
                      </div>
                    </div>
                  </div>
                  <div className='row margin-left-10 padding-top-10'>
                    <div className='col-md-6'>
                      <strong>Certificate Issuer:</strong> {interview.issuer}
                    </div>
                  </div>
                  <div className='row margin-left-10 padding-top-10'>
                    <div className='col-md-6'>
                      <strong>Certificate Issue Date: </strong>{' '}
                      {interview.issueDate ? moment(interview.issueDate).format('MM-DD-YYYY') : ' '}
                    </div>
                    <div className='col-md-6'>
                      <strong>Certificate Expiration Date: </strong>{' '}
                      {interview.issueDate ? moment(interview.expirationDate).format('MM-DD-YYYY') : ' '}
                    </div>
                  </div>

                  <div className='row margin-left-20 padding-top-10'>
                    <strong>Notes: </strong> {interview.notes}
                  </div>

                  <div className='row margin-left-20 padding-top-10'>
                    <strong>Document Name: </strong>

                    {interview.document
                      ? interview.document.map((item, index) => (
                          <span>
                            <a
                              href='#/'
                              onClick={() => downloadFile(item.userDefinedName)}
                              className='text-underline mr-3 padding-left-10'
                            >
                              {item.userDefinedName}
                            </a>
                          </span>
                        ))
                      : ''}
                  </div>
                </div>
              ))
            ) : (
              <div className='row margin-left-25'>
                Since there are no finished interviewes, no transcript generated.
              </div>
            )}
          </div>
          {elements ? (
            <div className='col-md-12'>
              <h4>
                {elements && elements.length > 0 ? 'The following Elements will be added to your Transcript. Please complete the Elements that need further information:' : ''}
              </h4>
              <table className='table'>
                <thead>
                  <tr>
                    <th>No</th>
                    <th class='width-10'>Code</th>
                    <th className='width-20'>Category</th>
                    <th>Question</th>
                    <th class='width-06 align-center'>Documention Required</th>
                    <th class='width-06 align-center'>Explanation Required</th>
                    <th class='width-06 align-center'>Completed</th>
                  </tr>
                </thead>
                <tbody>
                  {elements
                    ? elements.map((elementCredit, index) => (
                        <tr key={index} onDoubleClick={() => onDoubleClicked(index)}>
                          <td>{index + 1}</td>
                          <td>{elementCredit.element.reprivataCode}</td>
                          <td>{elementCredit.element.categoryName}</td>
                          <td>{elementCredit.element.question}</td>
                          <td class='align-center'>
                            {computeDocumentionRequired(elementCredit) ? (
                              <i class='fa fa-exclamation-circle icon-red' title='Credited'></i>
                            ) : (
                              <i class='fas fa-check' title='Marked Yes'></i>
                            )}
                          </td>
                          <td class='align-center'>
                            {elementCredit.element.explanationRequired && !elementCredit.userExplanation ? (
                              <i class='fa fa-exclamation-circle icon-red' title='Credited'></i>
                            ) : (
                              <i class='fas fa-check' title='Marked Yes'></i>
                            )}
                          </td>
                          <td class='align-center'>
                            {elementCredit.isCompleted ? (
                              <i class='fas fa-thumbs-up' title='Completed'></i>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Transcript;
