import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import UserCommentsAndDocument from '../components/UserCommentsAndDocument';

const UserCommentAndDocumentsDlg = ({ selectedElementCredit, showDialog, hideDialog, isReadOnly }) => {
  useEffect(() => {
    console.log(isReadOnly);
  }, [selectedElementCredit]);
  const handleClose = () => {
    hideDialog(false);
  };

  return (
    <>
      <Modal size='xl' show={showDialog} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Element Credit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserCommentsAndDocument
            selectedElementCredit={selectedElementCredit}
            hideDialog={() => hideDialog()}
            isReadOnly={isReadOnly}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserCommentAndDocumentsDlg;
