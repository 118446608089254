import React from 'react';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';

import 'react-notifications-component/dist/theme.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './App.css';
import './app/assets/fontawesome/all.min.css';
import './app/assets/css/sb-admin-2.min.css';
import './app/assets/css/add-property.css';
import './app/assets/css/style.css';

import configureStore from './app/redux/configure-store';
import AppRoutes from './routes';
import './app/axios/interceptor';
import { authService } from './app/redux/services';
import { actionType } from './app/redux/modules/auth';

const store = configureStore();

const isAuthenticated = authService.isAuthenticated();
if (isAuthenticated) {
  const authenticatedUserInfo = authService.getLoggedInUserInfo();
  store.dispatch({ type: actionType.AUTHENTICATED_USER, data: authenticatedUserInfo });
}

function App() {
  return (
    <Provider store={store}>
      <ReactNotification />
      <AppRoutes />
    </Provider>
  );
}

export default App;
