import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { documentAction } from '../../../redux/modules/documents';
import { notification } from '../../../redux/services';
import moment from 'moment-timezone';

const DocumentNameChange = ({ selectedDocument, hideDialog }) => {
  const dispatch = useDispatch();
  const [docName, setDocName] = useState(null);

  useEffect(() => {
    if (selectedDocument) setDocName(selectedDocument.userDefinedName);
  }, selectedDocument);

  const userExplanationChange = (event) => {
    const fileName = event.target.value;
    if (fileName) {
      setDocName(fileName);
      selectedDocument.userDefinedName = fileName;
    } else {
      selectedDocument.userDefinedName = '';
      setDocName('');
    }
  };

  const updateFileName = (selectedDocument) => {
    dispatch(documentAction.updateDocumentName(selectedDocument)).then((document) => {
      //  selectedElementCredit.userExplanation = document.data.userExplanation;
      notification.success(`File name updated`);
    });
  };

  const saveDataAndCloseform = () => {
    if (selectedDocument) updateFileName(selectedDocument);
    hideDialog();
  };

  return (
    <React.Fragment>
      <div className='body'>
        <div className='col-md-12'>
          <form>
            <div className='col-sm-12'>
              <div className='form-group row'>
                <label className='col-md-3'>{'Document'}</label>
                <div className='col-md-9'>
                  <input
                    type='text'
                    className='form-control'
                    value={docName}
                    onChange={userExplanationChange}
                  ></input>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 padding-top-10'>
                <button
                  type='button'
                  className='btn btn-primary float-right mr-1'
                  onClick={() => saveDataAndCloseform()}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentNameChange;
