import actionType from './action-type';

const initialState = {
  isLoading: false,
  documents: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case actionType.LOAD_DOCUMENTS:
      return {
        ...state,
        documents: action.data,
      };

    default:
      return state;
  }
};
