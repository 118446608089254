import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Compliances } from '../../modules/compliance';
import { Dashboard } from '../../modules/dashboard';
import ProtocolDetails from '../../modules/compliance/components/protocol-details';
import CompliancesSetup from '../../modules/compliance/components/compliances-setup';
import Question from '../../modules/interview/components/Question';
import InterviewDetail from '../../modules/interview/components/interview-detail';
import ComplianceCertificateDetail from '../../modules/certificate/components/compliance-certificate-detail';
import Interviews from '../../modules/interview/components/Interviews';
import Settings from '../../modules/settings/components/logo-upload';
import Profile from '../../modules/profile/components/profile';
import ReviewQuestions from '../../modules/interview/components/review-questions';
import AuditReport from '../../modules/audit-report/components/audit-report';
import ScoreCards from '../../modules/score-card/components/score-cards';
import ScoreCardViewer from '../../modules/score-card/components/score-card-viewer';
import Transcript from '../../modules/transcript/components/transcript';
import UploadComplianceCertificate from '../../modules/certificate/components/upload-compliance-certificate';
import ShowCredits from '../../modules/credits/components/show-credits';
import { DocumentList } from '../../modules/documents';
import { ReverseList } from '../../modules/reverse-maping';

const homeRoutes = () => (
  <React.Fragment>
    <Switch>
      <Route exact path='/dashboard' component={Dashboard} />
      <Route exact path='/settings' component={Settings} />
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/compliances' component={Compliances} />
      <Route exact path='/protocol-information/:id?' component={ProtocolDetails} />
      <Route exact path='/compliance-setup' component={CompliancesSetup} />
      <Route exact path='/complianceCertificateDetail' component={ComplianceCertificateDetail} />
      <Route
        exact
        path='/question/:complianceName?/:interviewId?/:interviewSessionId?/:questionIndex?'
        component={Question}
      />
      <Route exact path='/interviews/unfinished' component={Interviews} />
      <Route exact path='/interviews/all' component={Interviews} />
      <Route
        exact
        path='/interviews/scorecards/:organizationId?/session/:sessionId?'
        component={ScoreCardViewer}
      />
      <Route exact path='/interviews/:interviewId?' component={InterviewDetail} />
      <Route exact path='/interviews/ReviewQuestions/:complianceName?/:interviewId?/:interviewSessionId?' component={ReviewQuestions} />
      <Route exact path='/audit-report/:organizationId?' component={AuditReport} />
      <Route exact path='/transcript/:organizationId?' component={Transcript} />
      <Route exact path='/interviews/scorecards/:organizationId?' component={ScoreCards} />
      <Route exact path='/upload-compliance-certificate' component={UploadComplianceCertificate} />
      <Route exact path='/interview/credits' component={ShowCredits} />
      <Route exact path='/documents' component={DocumentList} />
      <Route exact path='/reverse-mapping' component={ReverseList} />
      <Redirect to={{ pathname: '/dashboard' }} />
    </Switch>
  </React.Fragment>
);

export default homeRoutes;
