import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from "@emotion/core";
import HashLoader from "react-spinners/ClipLoader";
import { complianceAction } from '../../../redux/modules/compliance';

import moment from 'moment-timezone';
import { interviewAction } from '../../../redux/modules/interview';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

const Compliances = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#fff000");

  const history = useHistory();

  const compliances = useSelector((state) => state.compliance.compliances);

  useEffect(() => {
    // For syncing categories with admin
    stableDispatch(interviewAction.syncAllCategories()).then(() => {
      stableDispatch(complianceAction.getAllFullCompliances()).then((response) => {
        stableDispatch(complianceAction.getCompliances());
        setLoading(false);
      });
    })
  }, [stableDispatch]);

  return (
    <React.Fragment>
      <div className='body sweet-loading'>
        <HashLoader color={color} loading={loading} css={override} size={150} />
        {
          (loading === false) ?
            <div className='row clearfix'>
              <div className='col-md-12'>
                <h2>Frameworks</h2>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Issuer</th>
                      <th>Description</th>
                      <th class='width-06'>Issue Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compliances.map((item, index) => (
                      <tr
                        className='cursor-pointer'
                        key={index}
                        onDoubleClick={() => history.push(`/protocol-information/${item.id}`)}
                      >
                        <td>{item.name}</td>
                        <td>{item.issuer}</td>
                        <td>{item.description}</td>
                        <td>{item.issuerDate ? moment(item.issuerDate).format('MM-DD-YYYY') : ''}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div> : ''
        }
      </div>
    </React.Fragment>
  );
};

export default Compliances;
