import { DocumentService as documentService } from '../../services';
import actionType from './action-type';

const getAllDocuments = (complianceData) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return documentService
    .getAllDocuments(complianceData)
    .then((response) => {
      dispatch({ type: actionType.LOAD_DOCUMENTS, data: response.data });
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({ type: actionType.IS_LOADING, data: false });
    });
};

const updateDocumentName = (doc) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return documentService
    .updateDocumentName(doc)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({ type: actionType.IS_LOADING, data: false });
    });
};

export default {
  getAllDocuments,
  updateDocumentName,
};
