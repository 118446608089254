import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/dashboard.css';
const Dashboard = () => {
  return (
    <React.Fragment>
      <div className='row dashboard-cards'>
      <Link  class='dashboard-card' to='/compliances'>        
            <div className='contents'>
              <i
                className='fa fa-file-text fa-5x cursor-pointer mr-2'
                title='Begin New Assessment'
              ></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Begin New Assessment</b>
              </h4>
            </div>
        </Link>

          <Link class='dashboard-card' to='/interviews/unfinished'>
            <div className='contents'>
              <i className='fa fa-pencil-square-o fa-5x cursor-pointer mr-2'
                title='Continue evaluation'>
              </i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Resume Assessment</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/interviews/all'>
            <div className='contents'>
              <i className='fa fa-list-alt fa-5x cursor-pointer mr-2' title='Checklist View'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>All Assessments</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/upload-compliance-certificate'>
            <div className='contents'>
              <i
                className='fas fa-certificate fa-5x cursor-pointer mr-2'
                title='Apply Completed Assessment Credits'
              ></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Apply Completed Assessment Credits</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/interviews/scorecards/1'>
            <div className='contents'>
              <i className='fas fa-chart-pie fa-5x cursor-pointer mr-2' title='Reports'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Score Cards</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/audit-report/1'>
            <div className='contents'>
              <i className='fa fa-user-secret fa-5x cursor-pointer mr-2' title='Reports'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Audit Report</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/transcript/1'>
            <div className='contents'>
              <i className='fa fa-clipboard-list fa-5x cursor-pointer mr-2' title='Reports'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Transcript</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/documents'>
            <div className='contents'>
              <i className='fa fa-file-alt fa-5x cursor-pointer mr-2' title='Documents'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Documents</b>
              </h4>
            </div>
          </Link>
          <Link class='dashboard-card' to='/reverse-mapping'>
            <div className='contents'>
              <i className='fa fa-link fa-5x cursor-pointer mr-2' title='/Reverse Mapping'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Reverse Mapping</b>
              </h4>
            </div>
          </Link>
        {/* <div class='dashboard-card'>
          <Link to='/compliance-setup'>
            <div className='contents'>
              <i className='fa fa-clipboard-list fa-5x cursor-pointer mr-2' title='Compliance Setup'></i>
            </div>
            <div class='footer-text'>
              <h4>
                <b>Compliance Setup</b>
              </h4>
              <h4>&nbsp;</h4>
            </div>
          </Link>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
