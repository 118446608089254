import React, { useEffect, useState, useCallback } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { complianceAction } from '../../../redux/modules/compliance';
import { interviewAction } from '../../../redux/modules/interview';
import { notification } from '../../../redux/services';
import Pdf from "react-to-pdf";
import '../styles/reverse-list.css';
import Table from 'react-bootstrap/Table'

const ref = React.createRef();

const ReverseList = () => {
  const [documents, setDocuments] = useState([]);
  const [frameworks, setInterviewsData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setInterviewsData([])
  }

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(complianceAction.getIssuedCompliances()).then(({ data }) => {
      data.map(item => item.checked = false)
      setDocuments(data);
    });
  }, [stableDispatch]);

  const rowChecked = (index) => {
    const newData = JSON.parse(JSON.stringify(documents))
    newData[index].checked = !newData[index].checked;
    setDocuments(newData)
  }
  const selectAll = (e) => {
    const data = JSON.parse(JSON.stringify(documents));
    data.map(item => item.checked = e.target.checked)
    setDocuments(data);
  }


  const compareProtocols = () => {
    setIsLoading(true)
    const selected = documents.filter(item => item.checked)
    if (!selected || !selected.length)
      notification.warning("Select protocol");
    else {
      const selectedIds = selected.map(item => item.id.toString())
      setShow(true);
      stableDispatch(complianceAction.getCompliancesReports(selectedIds)).then(({ data }) => {
        setIsLoading(false)
        //parseData(data);
        setInterviewsData(data)

      }).catch(() => setIsLoading(false));
    }
  }


  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <div className="row class-row-heading">
              <h2>Published Frameworks</h2>
              <Button variant="primary" onClick={compareProtocols}>
                Compare Frameworks
              </Button>
            </div>
            <table className='table'>
              <thead>
                <tr>
                  <th className='width-01'>
                    <Form.Check onChange={selectAll} type="checkbox" label="Select All" />
                  </th>
                  <th className='width-04'>Name</th>
                  <th className='width-04'>Issuer</th>
                  <th className='width-06'>Description</th>
                  <th className='width-02'>Date Issued</th>
                  {/* <th className='width-01'>Date Completed</th> */}
                </tr>
              </thead>
              <tbody>
                {documents.map((document, index) => (
                  <tr key={index} className='cursor-pointer'>
                    <td>
                      <div className="class-td-checkbox">
                        <Form.Check onChange={() => rowChecked(index)} type="checkbox" checked={document.checked} />
                        <label style={{ paddingTop: '1px' }}>{index + 1}</label>
                      </div>
                    </td>
                    <td>{document.name}</td>
                    <td>{document.issuer}</td>
                    <td>{document.description}</td>
                    <td>
                      {document.issuerDate ? moment(document.issuerDate).format('MM-DD-YYYY') : ''}
                    </td>
                    {/* <td>
                      {document.interviewEndTime ? moment(document.interviewEndTime).format('MM-DD-YYYY HH:MM') : ''}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Compare Frameworks</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {
              loading ? (
                <div className="spinner-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <div className='body' ref={ref}>
                  <div className='clearfix'>
                    <div className='row'>
                      {frameworks && frameworks.length ? (

                        frameworks.map((framework, index) => (
                          <div className='col-md-6'>
                            <h2>{framework.name}</h2>

                            <Table responsive="sm" >

                              <thead>
                                <tr>
                                  <th>Reprivata ID</th>
                                  <th>Control Number</th>
                                  <th>Paragraph Title</th>
                                  <th class='align-center'>Completed</th>
                                </tr>
                              </thead>

                              {framework.paragraphs.map((paragraph, index) => (
                                <tbody>
                                  {paragraph.questions.map((question, index) => (
                                    <tr className='cursor-pointer'>
                                      <td title={question.description}>{question.reprivataCode}</td>
                                      <td>{paragraph.controlNumber}</td>
                                      <td>{paragraph.controlTitle}</td>
                                      <td class='align-center'>
                                        {question.isCompleted ? <i class='fas fa-thumbs-up' title='Completed'></i> : <i style={{ color: 'red' }} class='fas fa-times' title='Not Completed'></i>}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ))}
                            </Table>

                          </div>
                        ))
                      ) : (
                        <label>There is no correlation between
                        controls for these frameworks.</label>
                      )}
                    </div>
                  </div>
                </div>

              )
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* <Pdf targetRef={ref} filename="comparison-report.pdf">
              {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
            </Pdf> */}
          </Modal.Footer>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default ReverseList;
