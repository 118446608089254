import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { interviewAction } from '../../../redux/modules/interview';


const ScoreCards = () => {
  const [interviews, setInterviews] = useState(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  let { organizationId } = useParams();  


  useEffect(() => {
    if (organizationId) {
      stableDispatch(interviewAction.getAllInterviewsWithSessions(organizationId)).then((response)=>{
        let interviews = response.data;
        setInterviews(interviews);
      });        
    }
  }, [organizationId]);

  const openScoreCard = (organizationId, sessionId, protocol) => {
      // TODO:aali organziationId is hardcoded for now, need to get this from user object.
      history.push(`/interviews/scorecards/${organizationId}/session/${sessionId}?framework=${encodeURIComponent(protocol) }`);
 }  

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
        {interviews ? interviews.map((interview, index) => (
            <div className='card dark-border'>
                <div className='card-header header-dark'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <i className={(interview && interview.interviewEndDateTime) ? 'fas fa-check icon-green' : 'fas fa-spinner icon-red'} aria-hidden='true'></i> &nbsp;{' '}
                      <strong>Assessment Framework:</strong> {interview ? interview.protocol : ''}
                    </div>
                    <div className='col-md-4'><strong>Assessment Start:</strong> {interview ? moment(interview.interviewStartDateTime).format('MM-DD-YYYY HH:MM:SS') : ''}</div>
                    <div className='col-md-4 align-right'>
                      <strong>Assessment End: </strong>{(interview && interview.interviewEndDateTime)  ? moment(interview.interviewEndDateTime).format('MM-DD-YYYY HH:MM:SS') : 'In-Progress'}
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                      <div className='col-md-12'>
                          <h4>Assessment Session</h4>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Session Start</th>
                                <th>Session End</th>
                              </tr>
                            </thead>
                            <tbody>
                              {interview && interview.interviewSessions ? interview.interviewSessions.map((session, index) => (
                                  <tr
                                    className= 'cursor-pointer'
                                    key={index}
                                    onDoubleClick={() => openScoreCard(1, session.id, interview.protocol)}

                                  >
                                    <td>{session.sessionNo}</td>
                                    <td>{session.sessionStartDateTime ? moment(session.sessionStartDateTime).format('MM-DD-YYYY HH:MM:SS') : ''}</td>
                                    <td>{session.sessionEndDateTime ? moment(session.sessionEndDateTime).format('MM-DD-YYYY HH:MM:SS') : 'In-Progress'}</td>
                                  </tr>
                                )) : ''}
                            </tbody>
                          </table>
                    </div>
                </div>
              </div>   
        )) : ''  }
        </div>
        
      </div>
    </React.Fragment>
  );
};

export default ScoreCards;
