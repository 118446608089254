import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import QuestionsList from './questions-list';
import Table from 'react-bootstrap/Table'


const ReviewQuestions = () => {
  const history = useHistory();
  let { interviewId } = useParams();
  let { interviewSessionId } = useParams();
  let { complianceName } = useParams();
  
  const questions = useSelector((state) => state.interview.interviewSessionDetail);

  const jumpToQuestion = (interviewId, questionIndex) => {
      history.push(`/question/${complianceName}/${interviewId}/${interviewSessionId}/${questionIndex}`);
  }  

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          <div className='col-md-12'>
            <h2>Review Responses</h2>
            <Table responsive >
              <thead>
                <tr>
                  <th>No</th>
                  <th>Reprivata ID</th>
                  <th>Control Category</th>
                  <th>Question</th>
                  <th class='align-center'>Assessment Explanation</th>
                  <th class='align-center'>Completed</th>
                  <th class='align-center'>Credited</th>
                </tr>
              </thead>
              <tbody>
                {questions
                  ? questions.map((question, index) => (
                      <tr
                        className='cursor-default'
                        key={index}
                        onDoubleClick={() => jumpToQuestion(interviewId, index + 1)}
                      >
                        <td>{index + 1}</td>
                        <td>{question.reprivataCode}</td>
                        <td>{question.category}</td>
                        <td>{question.question}</td>
                        <td>{question.userExplanation}</td>
                        <td class='align-center'>{question.isCompleted ? <i class='fas fa-thumbs-up' title='Completed'></i> : ''}</td>
                        <td class='align-center'>{question.gotCredit ? <i class='fas fa-check' title='Credited'></i> : ''}</td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReviewQuestions;
