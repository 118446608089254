import axios from 'axios';

import { authUrl, sharedConstant } from '../constants';

const complianceAdminApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class authService {
  static signIn(userInfo) {
    return axios.post(`${complianceAdminApiUrl}${authUrl.signIn}`, userInfo).then((response) => response);
  }

  static saveAuthData(route) {
    const { id, username } = JSON.parse(localStorage.getItem(sharedConstant.loggedInUserInfoContainer));
    const payload = {
      UserID: id,
      UserName: username,
      Url: route,
      formData: ""
    }
    return axios.post(`${complianceAdminApiUrl}${authUrl.saveData}`,  payload).then((response) => response);
  }

  static refreshToken(token) {
    return axios.post(`${complianceAdminApiUrl}${authUrl.refreshToken}`, { token })
      .then(({ data }) => {
        const { token } = data;
        // localStorage.setItem(sharedConstant.tokenContainer, token);
      })
  }

  static loginIntoApp(userInfo) {
    const { token, ...rest } = userInfo;
    localStorage.setItem(sharedConstant.tokenContainer, token);
    localStorage.setItem(sharedConstant.loggedInUserInfoContainer, JSON.stringify(rest));
  }

  static logoutFromApp() {
    localStorage.removeItem(sharedConstant.tokenContainer);
    localStorage.removeItem(sharedConstant.loggedInUserInfoContainer);
  }

  static getToken() {
    return localStorage.getItem(sharedConstant.tokenContainer);
  }

  static isAuthenticated() {
    const token = localStorage.getItem(sharedConstant.tokenContainer);

    return token ? true : false;
  }

  static getLoggedInUserInfo() {
    return JSON.parse(localStorage.getItem(sharedConstant.loggedInUserInfoContainer));
  }
}

export default authService;
