import actionType from './action-type';

const initialState = {
  isLoading: false,
  elementCredits: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOAD_ELEMENT_CREDITS:
      return {
        ...state,
        elementCredits: action.data,
      };

    default:
      return state;
  }
};
