import axios from 'axios';
import { url } from '../constants';

const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class ProfileService {
  static postProfileData(payload) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.profile.postProfileInfo}`, payload)
      .then((response) => response);
  }

  static getProfileData(id) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.profile.getProfileInfo}?UserID=${id}`)
      .then((response) => response);
  }
}

export default ProfileService;
