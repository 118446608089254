import { ScoreCardService } from '../../services';
import actionType from './action-type';

const AddCreditScore = (complianceInfo) => (dispatch) => {
  return ScoreCardService.AddCreditScore(complianceInfo)
    .then((response) => {
      dispatch({ type: actionType.CERTIFCATE_ELEMENTS_CREDIT, data: response.data });
      return response;
    })
    .catch((error) => {});
};

const getScoreCard = (sessionId) => (dispatch) => {
  dispatch({ type: actionType.IS_LOADING, data: true });

  return ScoreCardService.getScoreCard(sessionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getScoreCard,
  AddCreditScore,
};
