import axios from 'axios';

import { url } from '../constants';

//const complianceAdminApiUrl = process.env.REACT_APP_COMPLIANCE_ADMIN_API_URL;
const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class complianceService {
  static setupCompliances(complianceInfo) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.compliance.setupCompliance}`, complianceInfo)
      .then((response) => response);
  }

  static getCompliancesNames() {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.compliance.getActiveLockedInProgressCompliances}`)
      .then((response) => response);
  }
  static getCompliances() {
    var fullUrl = `${complianceAssessmentApiUrl}${url.compliance.getActiveLockedCompliances}`;
    return axios.get(fullUrl).then((response) => response);
  }
  static getIssuedCompliances() {
    const fullUrl = `${complianceAssessmentApiUrl}${url.compliance.getIssuedCompliances}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static getReverseMappingReport(payload) {
    const fullUrl = `${complianceAssessmentApiUrl}${url.compliance.getIssuedCompliancesReports}`;
    return axios.post(fullUrl, payload).then((response) => response);
  }

  static getAllFullCompliances() {
    var fullUrl = `${complianceAssessmentApiUrl}${url.compliance.getActiveLockedWithDetails}`;
    console.log('getAllFullCompliances URL ::::: ', fullUrl);
    return axios.get(fullUrl).then((response) => response);
  }

  // static getCompliance(id) {
  //   var fullUrl = `${complianceAdminApiUrl}${url.compliance.getCompliances}/${id}`;
  //   return axios.get(fullUrl).then((response) => response);
  // }

  static getFullCompliance(complianceId) {
    const fullUrl = url.compliance.getFullCompliance.replace('{complianceId}', parseInt(complianceId));
    return axios.get(`${complianceAssessmentApiUrl}${fullUrl}`, complianceId).then((response) => response);
  }

  static downloadOriginalDoc(fileName) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.compliance.downloadOriginalDoc}/${fileName}`, {
        responseType: 'blob',
      })
      .then((response) => response);
  }
}

export default complianceService;
