import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import QuestionsList from './questions-list';
import moment from 'moment-timezone';
import { interviewAction } from '../../../redux/modules/interview';
import './../styles/Interviews.css'
const InterviewDetail = () => {
  const [interview, setInterview] = useState(null);
  const [interviewLoading, setInterviewLoading] = useState(true);
  const [interviewQuestions, setInterviewQuestions] = useState(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  let { interviewId } = useParams();
  const [complianceName, setComplianceName] = useState('');

  useEffect(() => {
    if (interviewId) {
      stableDispatch(interviewAction.getFullInterviewDetail(interviewId)).then((response) => {
        setInterviewLoading(false)
        if (response) {
          let interview = response.data;
          setComplianceName(response.data.protocol);
          let interviewSession =
            interview.interviewSessions && interview.interviewSessions.length > 0
              ? interview.interviewSessions[interview.interviewSessions.length - 1]
              : [];
          let interviewQuestions = interviewSession.paragraphDetail
            ? interviewSession.paragraphDetail
            : [];
          setInterview(interview);
          console.log('interviewQuestions :::::: ', interviewQuestions);
          if (interviewQuestions && interviewQuestions.length) {
            interviewQuestions.map(({ questions }) => {
              return questions.sort((a,b) => a.paragraphControlNumber - b.paragraphControlNumber);
            })
          }
          setInterviewQuestions(interviewQuestions);
        }
      }).catch(err => {
        setInterviewLoading(false)
      });
    }
  }, [interviewId]);

  const jumpToQuestion = (interviewId, questionIndex) => {
    if (interview && interview.interviewEndDateTime == undefined) {
      stableDispatch(interviewAction.createInterviewSession(interviewId)).then((response) => {
        var interviewSessionId = response.data.id;
        history.push(`/question/${complianceName}/${interviewId}/${interviewSessionId}/${questionIndex}`);
      });
    }
  };

  return (
    <React.Fragment>
      <div className='body'>
        <div className='clearfix'>
          {interviewLoading ? (
            <div className='class-spinner'>
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
              <div className='card dark-border'>
                <div className='card-header header-dark'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <i className='fa fa-clipboard-list' aria-hidden='true'></i> &nbsp;{' '}
                      <strong>Assessment Framework:</strong> {interview ? interview.protocol : ''}
                    </div>
                    <div className='col-md-4'>
                      <strong>Assessment Start Date:</strong>{' '}
                      {interview ? moment(interview.interviewStartDateTime).format('MM-DD-YYYY HH:MM:SS') : ''}
                    </div>
                    <div className='col-md-4 align-right'>
                      <strong>Assessment End Date: </strong>
                      {interview && interview.interviewEndDateTime
                        ? moment(interview.interviewEndDateTime).format('MM-DD-YYYY HH:MM:SS')
                        : 'In-Progress'}
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12 padding-top-10'>
                      <strong>Framework Type:</strong> {interview ? interview.protocolFramework : ''}
                    </div>
                    <div className='col-md-12 padding-top-20'>
                      <strong>Framework Description:</strong> {interview ? interview.protocolDesc : ''}
                    </div>
                    <div className='col-md-12 padding-top-20'>
                      <strong>Framework Notes:</strong> {interview ? interview.protocolNotes : ''}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {interview ? (
            <QuestionsList
              heading={'Assessment Details'}
              interviewId={interview.id}
              interviewEndDate={interview.interviewEndDateTime}
              paragraphs={interviewQuestions}
              onDoubleClicked={jumpToQuestion}
            />
          ) : (
              ''
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InterviewDetail;
