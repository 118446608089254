import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { complianceAction } from '../../../redux/modules/compliance';

import moment from 'moment-timezone';

const CompliancesSetup = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();

  const compliances = useSelector((state) => state.compliance.compliances);

  useEffect(() => {
    stableDispatch(complianceAction.getCompliances());
  }, [stableDispatch]);

 const refreshProtocolList = () => {
  stableDispatch(complianceAction.getAllFullCompliances()).then((response) => {
    stableDispatch(complianceAction.setupCompliances(response.data)).then((response)=>{
       stableDispatch(complianceAction.getCompliances());
    });
  });
 }
  return (
    <React.Fragment>
      <div className='body'>
        {((compliances && compliances.length > 0)?
        
            <div className='row clearfix'>
              <div className='col-md-12'>
                  <h2>List of exisiting Compliances</h2>
                  <h5>If you don't see your required compliance in following list please click the button to refresh.</h5>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Issuer</th>
                          <th>Description</th>
                          <th className='width-06'>Issue Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {compliances.map((item, index) => (
                          <tr
                            className='cursor-pointer'
                            key={index}
                            onDoubleClick={() => history.push(`/protocol-information/${item.id}`)}

                          >
                            <td>{item.name}</td>
                            <td>{item.issuer}</td>
                            <td>{item.description}</td>
                            <td>{item.issuerDate ? moment(item.issuerDate).format('MM-DD-YYYY') : ''}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
              </div>
              <div className='col-md-12 align-left'>
                    <button
                      type='button'
                      onClick={() => refreshProtocolList()}
                      className='btn btn-primary btn-sm'
                    >
                      Refresh Protocol List
                    </button>
                  </div>           
            </div>        
        
        :
            <div className='row clearfix'>
              <div className='col-md-12'>
                  <h2>Compliances Setup</h2>            
              </div>
              <div className='col-md-12 margin-top-10'>
                <h5>Please click the button to get the protocols for your organization.</h5>
              </div>
              <div className='col-md-12 align-right'>
                  <button type='button' onClick={() => refreshProtocolList()} className='btn btn-primary btn-sm'>
                      Refresh Protocol List
                  </button>
              </div>           
            </div>        
        )}

      </div>
    </React.Fragment>
  );
};

export default CompliancesSetup;
