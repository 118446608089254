import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { authAction } from '../../../redux/modules/auth';
import ReprivataLogo from '../../../assets/img/reprivata.svg';

import '../styles/login.css';

const complianceAdminApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

const Signin = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const isSigningIn = useSelector((state) => state.auth.isSigningIn);
  console.log(props.target);


  const onLogin = (userInfo) => {
    dispatch(authAction.signIn(userInfo))
      .then(({ data }) => {
        //const { returnUrl = null } = data;
        var parsedUrl = require('url').parse(window.location.href);
        let returnUrl = new URLSearchParams(parsedUrl.search).get('target');
        history.push(returnUrl ? returnUrl : '/dashboard');
      })
      .catch(() => { });
  };

  return (
    <React.Fragment>
      <div className='signin-image'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 min-vh-100 d-flex flex-column justify-content-center'>
              <div className='row'>
                <div className='col-lg-6 col-md-8 mx-auto'>
                  <div className='card rounded shadow shadow-sm position-relative overflow-hidden'>
                    <div className='card-body'>
                      <div className='mb-3'>
                        <div className='col-md-12 text-center'>
                          <div>
                            <img className='reprivataLogo' alt='' src= {complianceAdminApiUrl+ "/companylogo/getCompanylogo"} ></img>
                            <br />
                            <h1>Assessment Management System</h1>
                          </div>
                        </div>
                      </div>
                      <React.Fragment>
                        <form onSubmit={handleSubmit(onLogin)}>
                          <div className='form-group'>
                            <label htmlFor='email'>Username</label>

                            <input
                              type='text'
                              placeholder='Enter Username'
                              autoComplete='false'
                              className='form-control'
                              name='username'
                              ref={register({
                                required: 'Email is required',
                              })}
                            />

                            {/* <input
                            type='text'
                            placeholder='Enter Username'
                            autoComplete='false'
                            className='form-control'
                            name='username'
                            ref={register({
                              required: 'Email is required',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Enter correct email address',
                              },
                            })}
                          /> */}
                            <span className='form-error'>
                              <ErrorMessage errors={errors} name='username' />
                            </span>
                          </div>

                          <div className='form-group'>
                            <label htmlFor='password'>Password</label>
                            <input
                              type='password'
                              name='password'
                              autoComplete='false'
                              placeholder='Enter password'
                              className='form-control'
                              ref={register({ required: 'Password is required.' })}
                            />
                            <span className='form-error'>
                              <ErrorMessage errors={errors} name='password' />
                            </span>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <button type='submit' className='btn btn-primary btn-block login-btn'>
                                {isSigningIn ? 'signing in...' : 'Log in'}
                              </button>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <p className='text-center'>
                                Forgot Password?{' '}
                                <a href='#/' id='forgotPassword'>
                                  Click Here
                                </a>
                              </p>
                            </div>
                          </div>
                        </form>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signin;
