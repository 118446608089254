import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authService } from '../../redux/services';

import userAvatar from '../../assets/img/default-avatar.png';
import ReprivataLogo from '../../assets/img/reprivata.svg';
const complianceAdminApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

const TopNavigation = () => {

  const authenticatedUserInfo = useSelector((state) => state.auth.authenticatedUserInfo);
  const dispatch = useDispatch();
  // const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  let location = useLocation();

  // useEffect(() => {
  //   if (location.pathname.endsWith('dashboard') {

  //   } 
  // }, [stableDispatch]);

  const logout = () => {
    authService.logoutFromApp();
    history.push('/sign-in');
  };

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <>
      <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
        <button id='sidebarToggleTop' className='btn btn-link d-md-none rounded-circle mr-3'>
          <i className='fa fa-bars'></i>
        </button>

        <a className='sidebar-brand d-flex align-items-center justify-content-start' href='#'>
          <div className='row sidebar-brand-icon'>
            <i className='logo-icon'>
                <img alt='Reprivata' src={complianceAdminApiUrl + "/companylogo/getCompanylogo"}></img>              
            </i>
            <h5 className='font-weight-bold logo-color padding-top-05'>AMS</h5>
          </div>
        </a>
        <div className='topbar-divider d-none d-sm-block'></div>
        <ul className='navbar-nav custom-nav'>
          <li className='nav-item  '>
            <NavLink activeClassName='active-nav' className='nav-link' to='/dashboard'>
              Dashboard
            </NavLink>
          </li>
        </ul>

        <ul className='navbar-nav ml-auto'>
          <div className='topbar-divider d-none d-sm-block'></div>

          <li className='nav-item dropdown no-arrow'>
            <a
              className='nav-link dropdown-toggle'
              href='#/'
              id='userDropdown'
              role='button'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span className='mr-2 d-none d-lg-inline text-gray-600 small'>
                {' '}
                {authenticatedUserInfo.username}{' '}
              </span>
              <img alt='' className='img-profile rounded-circle' src={userAvatar} />
            </a>

            <div
              className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
              aria-labelledby='userDropdown'
            >
              <a className='dropdown-item' onClick={() => navigateTo('/profile')} href='#/'>
                <i className='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                Profile
              </a>
              <a className='dropdown-item' onClick={() => navigateTo('/settings')} href='#/'>
                <i className='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                Settings
              </a>

              <div className='dropdown-divider'></div>
              <a className='dropdown-item' href='#/' data-toggle='modal' onClick={() => logout()}>
                <i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TopNavigation;
