const IS_LOADING = 'IS_LOADING';
const SETUP_INTERVIEW = 'SETUP_INTERVIEW';
const LOAD_INTERVIEW_SESSION_DETAIL = 'LOAD_INTERVIEW_SESSION_DETAIL';
const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
const FULL_INTERVIEW_DETAIL = 'FULL_INTERVIEW_DETAIL';
const LOAD_SCORECARD = 'LOAD_SCORECARD';
const LOAD_INTERVIEW_SESSION = 'LOAD_INTERVIEW_SESSION';
const LOAD_INTERVIEW_COMPLIANCES = 'LOAD_INTERVIEW_COMPLIANCES';
const SET_DISPLAYABLE_CREDITS = 'SET_DISPLAYABLE_CREDITS';
const COMPLIANCE_CREDIT_INFORMATION = 'COMPLIANCE_CREDIT_INFORMATION';
const CERTIFCATE_ELEMENTS_CREDIT = 'CERTIFCATE_ELEMENTS_CREDIT';

export default {
  IS_LOADING,
  SETUP_INTERVIEW,
  LOAD_INTERVIEW_SESSION_DETAIL,
  UPDATE_QUESTIONS,
  FULL_INTERVIEW_DETAIL,
  LOAD_SCORECARD,
  LOAD_INTERVIEW_SESSION,
  LOAD_INTERVIEW_COMPLIANCES,
  SET_DISPLAYABLE_CREDITS,
  COMPLIANCE_CREDIT_INFORMATION,
  CERTIFCATE_ELEMENTS_CREDIT,
};
