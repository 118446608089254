import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ProfileAction } from '../../../redux/modules/profile';
import { notification } from '../../../redux/services';
import constants from '../../../redux/constants/shared-constants';
import './../styles/profile.css'
const Profile = () => {
    const { register, handleSubmit, errors, setValue, getValues } = useForm();
    const dispatch = useDispatch();
    const [profileId, setProfileId] = useState(null);
    const stableDispatch = useCallback(dispatch, []);
    const fieldLabels = ['organizationName', 'address', 'city', 'zip', 'country'];
    useEffect(() => {
        const { id } = JSON.parse(localStorage.getItem(constants.loggedInUserInfoContainer));
        stableDispatch(ProfileAction.getProfileData(id)).then((response) => {
            fieldLabels.map(field => setValue(field, response.data[field]))
            const { id } = response.data;
            setProfileId(id)
        });
    }, [stableDispatch]);

    const saveProfile = (profileInfo) => {
        const { organizationId, id } = JSON.parse(localStorage.getItem(constants.loggedInUserInfoContainer));
        const payload = { ...profileInfo, userId: id, organizationID: organizationId, id: profileId };
        stableDispatch(ProfileAction.postProfileData(payload)).then((response) => {
            notification.successHeaderOnly(`Company profile updated.`);
            const currentInfo = JSON.parse(localStorage.getItem(constants.loggedInUserInfoContainer))
            const { organizationID, organizationName } = response.data;
            console.log("currentInfo --->", currentInfo)
            currentInfo.organizationId = organizationID;
            currentInfo.organizationName = organizationName;
            localStorage.setItem(constants.loggedInUserInfoContainer, JSON.stringify(currentInfo))
        });
    };

    return (
        <React.Fragment>
            <div className='body'>
                <div className='col-sm-8'>
                    <div className='card-header shadow-card-header'> Company Profile</div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit(saveProfile)}>
                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-md-3 class-label'>Organization Name</label>
                                    <div className='col-md-9'>
                                        {' '}
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='organizationName'
                                            placeholder='Organization Name'
                                            ref={register({ required: 'Organization name  is required' })}
                                        />
                                        <ErrorMessage errors={errors} name='organizationName' as={<span className='form-error' />} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-md-3 class-label'>Address</label>
                                    <div className='col-md-9'>
                                        {' '}
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='address'
                                            placeholder='Address'
                                            ref={register({ required: 'Address  is required' })}
                                        />
                                        <ErrorMessage errors={errors} name='address' as={<span className='form-error' />} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-md-3 class-label'>City</label>
                                    <div className='col-md-9'>
                                        {' '}
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='city'
                                            placeholder='City'
                                            ref={register({ required: 'City is required' })}
                                        />
                                        <ErrorMessage errors={errors} name='city' as={<span className='form-error' />} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-md-3 class-label'>Zip Code</label>
                                    <div className='col-md-9'>
                                        {' '}
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='zip'
                                            placeholder='Zip Code'
                                            ref={register({ required: 'Zip code is required' })}
                                        />
                                        <ErrorMessage errors={errors} name='zip' as={<span className='form-error' />} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-md-3 class-label'>Country</label>
                                    <div className='col-md-9'>
                                        {' '}
                                        <select
                                            type='text'
                                            className='form-control'
                                            name='country'
                                            placeholder='Enter country'
                                            ref={register({ required: 'Country is required' })}
                                        >
                                            <option value=''>Select Compliance </option>
                                            {[{ id: 1, name: 'USA' }, { id: 2, name: 'Canada' }].map((item, index) => (
                                                <option key={index} value={item.name} selected={item.name == 'Canada'}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <ErrorMessage errors={errors} name='country' as={<span className='form-error' />} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='form-group row'>
                                    <div className='col-md-1 offset-11'>
                                        <button
                                            type='submit'
                                            className='btn btn-primary btn-sm next'
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Profile;
