import axios from 'axios';
import { url } from '../constants';

//const complianceAdminApiUrl = process.env.REACT_APP_COMPLIANCE_ADMIN_API_URL;
const complianceAssessmentApiUrl = process.env.REACT_APP_COMPLIANCE_ASSESSMENT_API_URL;

class interviewService {
  static setupInterview(compliacneData) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.interview.setupInterview}`, compliacneData)
      .then((response) => response);
  }

  static createInterviewSession(interviewId) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.interview.createInterviewSession}/${interviewId}`)
      .then((response) => response);
  }

  getInterviewCompliances;

  static getInterviewCompliances() {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.GetInterviewCompliances}`)
      .then((response) => response);
  }

  static syncCategoriesWithAdmin() {
    const fullUrl = `${complianceAssessmentApiUrl}${url.category.syncCategories}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static AddCreditScore(complianceInfo) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.interview.addCreditScore}`, complianceInfo)
      .then((response) => response);
  }

  static getInterviewSessionDetail(interviewSessionId) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.interviewSessionDetail}/${interviewSessionId}`)
      .then((response) => response);
  }

  static getInterviewQuestionStatus(type) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.questionStatus}?Type=${type}`)
      .then((response) => response);
  }

  static getFullInterviewDetail(id) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.fullInterviewDetail}/${id}`)
      .then((response) => response);
  }

  static downloadOriginalDoc(fileName, id) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.downloadOriginalDoc}/${fileName} /${id}`, {
        responseType: 'blob',
      })
      .then((response) => response);
  }

  static updateInterviewSessionDetail(sessionId, questions, interviewId) {
    var totalQuestion = questions.length;
    //  var viewedQuestions = questions.filter((x) => x.isViewed);
    return axios
      .put(
        `${complianceAssessmentApiUrl}${url.interview.updateInterview}/${sessionId}/${interviewId}/${totalQuestion}`,
        questions
      )
      .then((response) => response);
  }

  static getUnfinishedInterviews(organizationId) {
    const fullUrl = `${complianceAssessmentApiUrl}${url.interview.unfinishedInterviews.replace(
      '{organizationId}',
      organizationId
    )}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static getAllInterviews(organizationId) {
    const fullUrl = `${complianceAssessmentApiUrl}${url.interview.allInterviews.replace(
      '{organizationId}',
      organizationId
    )}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static getFullInterviewsForOrganization(organizationId) {
    const fullUrl = `${complianceAssessmentApiUrl}${url.interview.fullInterviews.replace(
      '{organizationId}',
      organizationId
    )}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static getAllInterviewsWithSessions(organizationId) {
    const fullUrl = `${complianceAssessmentApiUrl}${url.interview.allInterviewsWithSessions.replace(
      '{organizationId}',
      organizationId
    )}`;
    return axios.get(fullUrl).then((response) => response);
  }

  static getElementCreditForOrganization(organizationId) {
    return axios
      .get(`${complianceAssessmentApiUrl}${url.interview.getCreditElementForOrg}/${organizationId}`)
      .then((response) => response);
  }

  static UploadQuestionDocument(question) {
    return axios
      .post(`${complianceAssessmentApiUrl}${url.interview.updateInterview}`, question)
      .then((response) => response);
  }

  static updateElementCredit(elemenrCredit) {
    const fullurl = `${complianceAssessmentApiUrl}${url.interview.updateElementCredit}`;
    return axios.post(fullurl, elemenrCredit).then((response) => response);
  }
}

export default interviewService;
