import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { useBeforeunload } from 'react-beforeunload';

import { interviewAction } from '../../../redux/modules/interview';
import DocumentNameChangeDlg from './documentNameChangeDlg';
import { notification } from '../../../redux/services';
import Collapsible from 'react-collapsible';
import SunEditor from 'suneditor-react';
import './../styles/Question.css';
import { Spinner } from 'react-bootstrap';


const Question = () => {
  const { register, handleSubmit, errors, setValue, getValues, reset, trigger } = useForm();
  const [interviewQuestions, setInterviewQuestions] = useState(null);
  const [totallQuestions, setTotallQuestions] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [originalDoc, setOriginalDoc] = useState(null);
  const [saveAndQuitSpinner, setSaveAndQuitSpinner] = useState(false);


  const [currentInterviewQuestion, setCurrentInterviewQuestion] = useState(null);
  const [isYes, setIsYes] = useState(null);
  const [dropOneValue, setDropOneValue] = useState(null);
  const [dropTwoValue, setDropTwoValue] = useState(null);
  const [isNo, setIsNo] = useState(null);
  const [isNoAnswer, setIsNoAnswer] = useState(true);
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [userExplanation, setUserExplanation] = useState('');
  const [docDiscription, setDocDiscription] = useState('');


  const [userDefineFileName, setUserDefineFileName] = useState('');
  const [explanationRequired, setExplanationRequired] = useState(false);
  const [documentRequired, setDocumentRequired] = useState(false);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [disableControlImplementationThrough, setDisableControlImplementationThrough] = useState(false);

  

  const [documents, setDocuments] = useState([]);
  const [impStatus, setImplementationStatus] = useState([]);
  const [controlStatus, setControlImplementedThrough] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [clickedFile, setClickedFile] = useState(null);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  let { interviewId } = useParams();
  let { questionIndex } = useParams();
  let { interviewSessionId } = useParams();
  let { complianceName } = useParams();

  useEffect(() => {
    if (interviewSessionId && interviewQuestions == null) {
      stableDispatch(interviewAction.getInterviewSessionDetail(interviewSessionId))
        .then((data) => {
          var questions = data.data;

          setInterviewQuestions(questions);
          if (!questionIndex || questionIndex < 1) {
            questionIndex = 0;
          } else {
            questionIndex = questionIndex - 1;
          }
          setCurrentQuestionIndex(questionIndex);
          changeSelectedQuestion(questions, questionIndex);
          setTotallQuestions(questions.length);
        })
        .catch((e) => {
          console.log(e);
        });
      stableDispatch(interviewAction.getInterviewQuestionStatus('IMS'))
        .then(({ data }) => {
          setImplementationStatus(data)
        })
      stableDispatch(interviewAction.getInterviewQuestionStatus('CIT'))
        .then(({ data }) => {
          setControlImplementedThrough(data)
        })
    }
  }, [interviewSessionId]);

  useBeforeunload(() => {
    // if (interviewQuestions) {
    //   computeIsCompleted();
    //   dispatch(interviewAction.updateInterviewSessionDetail(0, interviewQuestions, interviewId));
    // }
  });

  useEffect(() => {
    return function cleanup() {
      // if (interviewQuestions) {
      //   computeIsCompleted();
      //   dispatch(interviewAction.updateInterviewSessionDetail(0, interviewQuestions, interviewId));
      // }
    };
  }, []);

  const changeSelectedQuestion = (questions, index) => {
    if (questions) {
      if (currentInterviewQuestion) {
        // update the documents in to question to add them in store
        currentInterviewQuestion.documents = [...documents];
      }

      var currentQuestion = questions[index];
      currentQuestion.isViewed = true;
      console.log('currentQuestion :::::: ', currentQuestion);
      setCurrentInterviewQuestion(currentQuestion);
      computerYesNo(currentQuestion);
      setDocumentRequired(currentQuestion.documentRequired);
      setExplanationRequired(currentQuestion.explanationRequired);
      setIsFollowUp(currentQuestion.isFaged);
      currentQuestion.userExplanation
        ? setUserExplanation(currentQuestion.userExplanation)
        : setUserExplanation('');

      currentQuestion.documents ? setDocuments(currentQuestion.documents) : setDocuments([]);
      const documentInfo = {
        originalDoc: currentQuestion.originalDoc,
      };
      setDocumentInfo(documentInfo);

    }
  };

  const computerYesNo = (currentQuestion) => {
    if (currentQuestion.isYes) {
      setIsYes(true);
      setIsNo(null);
      setIsNoAnswer(null);
    } else if (currentQuestion.isYes == false) {
      setIsNo(true);
      setIsYes(null);
      setIsNoAnswer(null);
    } else {
      setIsNoAnswer(true);
      setIsYes(null);
      setIsNo(null);
    }
  };

  const computeIsCompleted = () => {
    if (
      // currentInterviewQuestion.isYes &&
      (currentInterviewQuestion.isFaged == null || currentInterviewQuestion.isFaged == false)
    ) {
      if (!explanationRequired && !documentRequired) {
        currentInterviewQuestion.isCompleted = true;
      } else if (
        explanationRequired &&
        userExplanation &&
        documentRequired &&
        documents &&
        documents.length > 0
      ) {
        currentInterviewQuestion.isCompleted = true;
      } else if (explanationRequired && userExplanation && !documentRequired) {
        currentInterviewQuestion.isCompleted = true;
      } else if (documentRequired && documents && documents.length > 0 && !explanationRequired) {
        currentInterviewQuestion.isCompleted = true;
      }
    }
  };

  const computerInterviewCompleted = () => {
    var completedQuestions = interviewQuestions.filter((q) => q.isCompleted);
    var msg = 'There are no more questions. Do you want to save and quit?';

    if (completedQuestions.length == totallQuestions) {
      msg = 'There are no more questions. Do you want to save and quit?';
    }

    SaveAndQuitConfirmDialog(msg);
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      var previousIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(previousIndex);
      changeSelectedQuestion(interviewQuestions, previousIndex);
    }
  };

  const handleNext = () => {
    try {
      if (currentInterviewQuestion.documentRequired && !documents.length) {
        // eslint-disable-next-line no-throw-literal
        throw "Document Required!"
      }
      if (currentInterviewQuestion.explanationRequired && !userExplanation) {
        // eslint-disable-next-line no-throw-literal
        throw "Explanation Required!"
      }
      let nextIndex = currentQuestionIndex + 1;
      setDropTwoValue("")
      setDropOneValue("")
      computeIsCompleted();
      if (interviewQuestions.length > nextIndex) {
        setCurrentQuestionIndex(nextIndex);
        changeSelectedQuestion(interviewQuestions, nextIndex);
      } else {
        computerInterviewCompleted();
      }
    } catch (error) {
      notification.error(error);
    }
  };

  const handleSaveQuit = () => {
    try {
      // computeIsCompleted();
      setSaveAndQuitSpinner(true);
      dispatch(
        interviewAction.updateInterviewSessionDetail(interviewSessionId, interviewQuestions, interviewId)
      ).then(() => {
        setSaveAndQuitSpinner(false);

        notification.success(`Questions saved`);
        let organizationId = 1; // TODO:: Get this from user object later on
        history.push(`/interviews/scorecards/${organizationId}/session/${interviewSessionId}`);
      });
    } catch (error) {
      setSaveAndQuitSpinner(false);
      notification.error(error);
    }
  };

  const handleReviewQuestions = () => {
    history.push(`/interviews/ReviewQuestions/${complianceName}/${interviewId}/${interviewSessionId}`);
  };

  const handleFollowUp = () => {
    var isFollowChanged = !isFollowUp;
    setIsFollowUp(isFollowChanged);
    currentInterviewQuestion.isFlaged = isFollowChanged;
    setCurrentInterviewQuestion(currentInterviewQuestion);
  };

  const handleYesCheck = (event) => {
    const iYes = event.target.value == 'on' ? true : false;
    setIsYes(iYes);
    setIsNo(!iYes);
    setIsNoAnswer(null);
    currentInterviewQuestion.isYes = iYes;
    setCurrentInterviewQuestion(currentInterviewQuestion);
  };

  const handleNoCheck = (event) => {
    const isNo = event.target.value == 'on' ? true : false;
    setIsNo(isNo);
    setIsYes(!isNo);
    setIsNoAnswer(null);
    currentInterviewQuestion.isYes = !isNo;
    setCurrentInterviewQuestion(currentInterviewQuestion);
  };

  const handleNoAnswerCheck = (event) => {
    const isNoAnswer = event.target.value == 'on' ? true : false;
    setIsNoAnswer(isNoAnswer);
    setIsNo(null);
    setIsYes(null);
    currentInterviewQuestion.isYes = null;
    setCurrentInterviewQuestion(currentInterviewQuestion);
  };
  const handleImplementationChange = (event) => {
    let question = currentInterviewQuestion;
    setDropOneValue(event.target.value);
    question.implementationStatusID = event.target.value;
    setCurrentInterviewQuestion(question);
    console.log("event.target.value",event.target.value);
    console.log("event.target", event.target);

    if(event.target.value == 4 || event.target.value == 5)
    {
      setDisableControlImplementationThrough(true);
      setDropTwoValue("");
      question.implementationThroughID = "";
    } else {
    setDisableControlImplementationThrough(false);
    }
    setCurrentInterviewQuestion(question);

  }
  const handleControlChange = (event) => {
    let question = currentInterviewQuestion;
    setDropTwoValue(event.target.value);
    question.implementationThroughID = event.target.value;
    setCurrentInterviewQuestion(question)
  }
  const onDocDisChange = (event) => {
    setDocDiscription(event.target.value)
  }
  const handleChange = (event) => {
    const { originalDoc } = getValues();
    if (originalDoc.length) {
      setOriginalDoc(originalDoc);
      documentInfo.originalDoc = originalDoc;
      documentInfo.userDefinedName = docDiscription || originalDoc[0].name;;
      documentInfo.srcDocumentName = originalDoc[0].name;
      uploadFile(documentInfo);
    } else {
    }
  };

  const getFormData = (docinfo) => {
    const formData = new FormData();
    for (const key of Object.keys(docinfo)) {
      const keyValue = docinfo[key];
      if (key === 'originalDoc') {
        console.log(keyValue[0]);
        formData.append('originalDoc', keyValue[0]);
      } else {
        formData.append(key, keyValue);
      }
    }
    return formData;
  };

  // const handleSaveQuit = () => {
  //   computeIsCompleted();
  //   dispatch(
  //     interviewAction.updateInterviewSessionDetail(interviewSessionId, interviewQuestions, interviewId)
  //   ).then(() => {
  //     notification.success(`Questions saved`);
  //     let organizationId = 1; // TODO:aali: Get this from user object later on
  //     history.push(`/interviews/scorecards/${organizationId}/session/${interviewSessionId}`);
  //   });
  // };

  const downloadFile = (docName, id) => {
    dispatch(interviewAction.downloadOriginalDoc(docName, id));
  };

  const userExplanationChange = (event) => {
    const userExplanation = event.target.value;
    if (userExplanation) {
      setUserExplanation(userExplanation);
      currentInterviewQuestion.userExplanation = userExplanation;
    } else {
      setUserExplanation('');
      currentInterviewQuestion.userExplanation = '';
    }
  };

  const SaveAndQuitConfirmDialog = (message = null) => {
    try {
      if (currentInterviewQuestion.documentRequired && !documents.length) {
        // eslint-disable-next-line no-throw-literal
        throw "Document Required!"
      }
      if (currentInterviewQuestion.explanationRequired && !userExplanation) {
        // eslint-disable-next-line no-throw-literal
        throw "Explanation Required!"
      }
      if (interviewQuestions.length > currentQuestionIndex) {
        setCurrentQuestionIndex(currentQuestionIndex);
        changeSelectedQuestion(interviewQuestions, currentQuestionIndex);
      }
      computeIsCompleted();
      if (!message) {
        let completedQuestions = interviewQuestions.filter((q) => q.isCompleted);
        message = 'Are you sure you want to quit the current session?';
        if (completedQuestions.length === totallQuestions) {
          message = 'There are no more questions. Do you want to save and quit?';
        }
      }
      confirmAlert({
        title: 'Save & Quit',
        message,
        buttons: [
          {
            label: 'Yes',
            onClick: () => handleSaveQuit(),
          },
          {
            label: 'No',
          },
        ],
      });
    } catch (error) {
      notification.error(error)
    }

  };

  const uploadFile = (documentInfo) => {
    // make sure same document is not previosly loaded
    if (documents.filter((x) => x.documentName == documentInfo.srcDocumentName).length == 0) {
      const formData = getFormData(documentInfo);
      formData.append('id', 0);
      formData.append('elementId', currentInterviewQuestion.elementId);
      formData.append('isComplianceDocument', false);
      formData.append('srcDocumentName', documentInfo.srcDocumentName);
      dispatch(interviewAction.uploadQuestionDoc(formData)).then((document) => {
        var docs = [];
        docs = [document.data, ...documents];

        setDocuments(docs);
        notification.success(`File uploaded`);
      });
    }
  };

  const viewQuestion = () => {
    setShowQuestionDialog(true);
  };

  const onHideQuestionDialog = () => {
    setShowQuestionDialog(false);
  };

  const onDoubleClicked = (item) => {
    setClickedFile(item);

    viewQuestion();
    console.log(showQuestionDialog);
  };

  return (
    <React.Fragment>
    { saveAndQuitSpinner ? (
                <div className="spinner-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : 
      (
        <div>
      <DocumentNameChangeDlg
        selectedDocument={clickedFile}
        showDialog={showQuestionDialog}
        hideDialog={onHideQuestionDialog}
      />
      <div className='row clearfix'>
        <div className='col-sm-8'>
          <div className='row'>
            <div className='col'>
              <h4 className='text-center'>{complianceName ? complianceName : ''}</h4>
              <h5 className='text-center'>
                Question {currentQuestionIndex + 1} of {totallQuestions}{' '}
              </h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(uploadFile)}>
            <div className='row'>
              <div className='col-md-1 offset-11'>
                <div className='form-group form-check '>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='followupcheckbox'
                    name='followupcheckbox'
                    checked={isFollowUp}
                    onChange={() => {
                      handleFollowUp();
                    }}
                  />

                  <i class='fas fa-flag icon-blue' title='Flagged'></i>
                  <label className='form-check-label' for='followupcheckbox'></label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-11'>
                <div className='form-group form-check font-small'>
                  <strong>Reprivata Code :</strong>
                  {currentInterviewQuestion ? currentInterviewQuestion.reprivataCode : ''}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-11'>
                <div className='form-group form-check font-small'>
                  <strong>Category:</strong>{' '}
                  {currentInterviewQuestion ? currentInterviewQuestion.category : ''}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group form-check'>
                  <textarea
                    className='form-control'
                    name='description'
                    rows='3'
                    value={currentInterviewQuestion ? currentInterviewQuestion.description : ''}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group form-check pt-3'>
                  <label className='form-check-label'>Question:</label>
                  <textarea
                    className='form-control text-left fontBold'
                    name='qestion'
                    rows='3'
                    readonly
                    value={currentInterviewQuestion ? currentInterviewQuestion.question : ''}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group form-check'></div>
              </div>
              {currentInterviewQuestion && currentInterviewQuestion.gotCredit ? (
                <div className='col-md-12 apply-credits'>
                  <strong>
                    You don't have to answer this question since you have already been given credit for this
                    question.
                  </strong>
                </div>
              ) : (
                  ''
                )}
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group form-check pt-3'>
                  <label className='form-check-label'>Implementation Status </label>
                  <select
                    type='text'
                    className='form-control'
                    name='country'
                    value={currentInterviewQuestion?.implementationStatusID || ""}
                    onChange={handleImplementationChange}
                    placeholder='Enter country'
                  >
                    <option value=''>Select</option>
                    {impStatus.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group form-check pt-3'>
                  <label className='form-check-label'>Control Implemented Through </label>
                  <select
                    type='text'
                    className='form-control'
                    name='country'
                    value={currentInterviewQuestion?.implementationThroughID || ""}
                    onChange={handleControlChange}
                    placeholder='Enter country'
                    disabled={disableControlImplementationThrough}

                  >
                    <option value=''>Select</option>
                    {controlStatus.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 border border-dark bg-light'>
                <div className='form-group form-check pt-3'>
                  <label
                    className={classNames({
                      'form-check-label': !explanationRequired,
                      'form-check-label colorred': explanationRequired,
                    })}
                  >
                    {explanationRequired ? 'Explanation Required (*)' : 'Please Explain'}
                  </label>
                  <textarea
                    className='form-control'
                    name='otherfields'
                    rows='3'
                    value={userExplanation}
                    onChange={userExplanationChange}
                  ></textarea>
                  <div className='row pt-12'>
                    <div className='col-md-4'>
                      <label
                        className={classNames({
                          'form-check-label': !documentRequired,
                          'form-check-label colorred': documentRequired,
                        })}
                      >
                        {documentRequired ? 'Document Required (*)' : 'Supporting Document'}
                      </label>
                    </div>
                    <div className=' col-md-2 form-group form-check'></div>
                  </div>
                  <div className='row pt-12'>

                    <input className='col-md-4 pt-12 rounded-sm border border-dark ml-3' id='docDiscription' name="docDiscription" onChange={onDocDisChange} placeholder='Enter Document Description' ref={register({ required: false })} />
                    <div className='col-md-3'>
                      <input
                        id='originalDocFileUploadId'
                        type='file'
                        name='originalDoc'
                        ref={register(documentInfo ? {} : { required: 'Please select a file' })}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                      />

                      <button
                        type='button'
                        onClick={() => document.getElementById('originalDocFileUploadId').click()}
                        className='btn btn-primary btn-sm margin-right-10'
                      >
                        Upload File
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {documents && documents.length > 0 ? (
                    <div className='col-md-12'>
                      <table className='table'>
                        <thead className='header'>
                          <tr>
                            <th></th>
                            <th>Supporting Document</th>
                            <th>Upload Date</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents
                            ? documents.map((item, index) => (
                              <tr key={index} onDoubleClick={() => onDoubleClicked(item)}>
                                <td className='width-01'>{index + 1}</td>
                                <td>{item.userDefinedName}</td>
                                <td>
                                  {item.createdDate ? moment(item.createdDate).format('MM-DD-YYYY') : ''}
                                </td>
                                <td>
                                  <a
                                    href='#/'
                                    onClick={() => downloadFile(item.userDefinedName, item.id)}
                                    className='text-underline mr-3'
                                  >
                                    View
                                    </a>
                                </td>
                              </tr>
                            ))
                            : ''}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                      ''
                    )}
                </div>
              </div>
            </div>

            <div className='row pt-4'>
              <div className='col-md-4'>
                <button
                  type='button'
                  onClick={() => handleReviewQuestions()}
                  className='btn btn-primary btn-sm'
                >
                  Review Responses
                </button>
              </div>

              <div className='col-md-8 text-right'>
                {currentQuestionIndex != 0 ? (
                <button type='button' onClick={() => handlePrevious()} className='btn btn-primary btn-sm '>
                  &lt; Previous Question
                </button>) : ('')}
                <button
                  type='button'
                  onClick={() => handleNext()}
                  className='btn btn-primary btn-sm next margin-right-10'
                >
                  Next Question &gt;
                </button>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={() => SaveAndQuitConfirmDialog()}
                >
                  Save & Quit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='col-sm-4'>
          <div className='card dark-border'>
            <div className='card-header header-dark'>
              <div className='row'>
                <div className='col-md-8'>
                  <i className='fa fa-clipboard-list' aria-hidden='true'></i> &nbsp;{' '}
                  <strong>Paragraph Information:</strong>
                </div>
                <div className='col-md-4 align-right'>
                  <strong>
                    {currentInterviewQuestion && currentInterviewQuestion.paragraphOptional
                      ? 'Not Required'
                      : 'Required'}
                  </strong>
                </div>
              </div>
            </div>
            <div className='card-body class-background'>
              <div className='row paragraph-information'>
                <div className='col-md-12 padding-top-10'>
                  <strong>Framework Type:</strong> {complianceName ? complianceName : ''}
                </div>
                <div className='col-md-12 padding-top-10'>
                  <strong>Category:</strong>{' '}
                  {currentInterviewQuestion ? currentInterviewQuestion.paragraphCategory : ''}
                </div>
                <div className='col-md-12 padding-top-10'>
                  <strong>Focus Group:</strong>{' '}
                  {currentInterviewQuestion ? currentInterviewQuestion.group : ''}
                </div>
                <div className='col-md-12 padding-top-10'>
                  <strong>Maturity Level:</strong>{' '}
                  {currentInterviewQuestion ? currentInterviewQuestion.paragraphMaturityLevel : ''}
                </div>
                <div className='col-md-12 padding-top-10'>
                  <strong>Control Title:</strong>{' '}
                  {currentInterviewQuestion ? currentInterviewQuestion.paragraphControlTitle : ''}
                </div>
                <div className='col-md-12 padding-top-10'>
                  <strong>Control Number:</strong>{' '}
                  {currentInterviewQuestion ? currentInterviewQuestion.paragraphControlNumber : ''}
                </div>
                <div className='col-md-12 padding-top-10'>
                  <strong>Control Discussion:</strong>
                  {
                    <SunEditor
                      showToolbar={false}
                      name='text'
                      setContents={currentInterviewQuestion ? currentInterviewQuestion.paragraphText : ''}
                      setDefaultStyle='font-family: Arial;'
                      setOptions={{
                        height: 450,
                      }}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      ) 
      }
    </React.Fragment>
  );
};

export default Question;
